import { Component, OnInit } from '@angular/core';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from 'src/app/services/http.service';

@Component({
    selector: 'app-whatsnew-new',
    templateUrl: './whatsnew-new.component.html',
    styleUrls: ['./whatsnew-new.component.css']
})

export class WhatsnewNewComponent implements OnInit {
    whatsnewList: any = [];

    constructor(
        private httpService: HttpService,
    ) { }

    ngOnInit() {
        this.getWhatsNew();
    }

    getWhatsNew() {
        this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getNewsList', {}).subscribe(res => {
            this.whatsnewList = res.data;
        });
    }
}
