import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';

@Component({
    selector: 'app-doctor-home-bot-grp',
    templateUrl: './doctor-home-bot-grp.component.html',
    styleUrls: ['./doctor-home-bot-grp.component.css']
})

export class DoctorHomeBotGrpComponent implements OnInit {
    constructor(
        public router: Router,
        private doctorData: DoctorDataService,
    ) { }

    ngOnInit() {
    }

    myAppointment() {
        this.router.navigate(['/doctor/appointments']);
    }

    toAnnouncements() {
        this.router.navigate(['/announcements-new']);
    }

    toClaims() {
        this.router.navigate(['/doctor/claims']);
    }

    toClaimsHistory() {
        this.router.navigate(['/doctor/claims-history']);
    }

    toNotes() {
        this.router.navigate(['/doctor-note']);
    }

    toInvoices() {
        this.router.navigate(['/invoice']);
    }

    toFeedback() {
        location.href = "mailto:support@ourcheckup.com";
    }
}
