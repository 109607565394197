import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import appConstants from 'src/app/config/app.constants';

@Injectable()
export class DataService {
	private subscriber_id = new BehaviorSubject('');
	private quartelyReport = new BehaviorSubject({});
	private yearlyReport = new BehaviorSubject({});
	private profileData = new BehaviorSubject({});
	private triMonthlyData = new BehaviorSubject({});
	private yearlyData = new BehaviorSubject({});
	private username = new BehaviorSubject<string>("Username");
    private httpService: HttpService;

	getSubscriberId = this.subscriber_id.asObservable();
	getQuartelyReport = this.quartelyReport.asObservable();
	getYearlyReport = this.yearlyReport.asObservable();
	getProfileData = this.profileData.asObservable();
	getTriMonthlyData = this.triMonthlyData.asObservable();
	getYearlyData = this.yearlyData.asObservable();
	currentUsername = this.username.asObservable();

	constructor(
        httpService: HttpService,
	) { this.httpService = httpService; }

	setSubscriberId(message: string) {
		this.subscriber_id.next(message)
	}

	setQuartelyReport(message: Object) {
		this.quartelyReport.next(message)
	}

	setYearlyReport(message: Object) {
		this.yearlyReport.next(message)
	}

	setProfileData(message: Object) {
		this.profileData.next(message)
	}

	setTriMonthlyData(message: Object) {
		this.triMonthlyData.next(message)
	}

	setYearlyData(message: Object) {
		this.yearlyData.next(message)
	}

	updateUsername(u_name: string) {
		this.username.next(u_name);
	}

	getActiveTeleSession() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getActiveTeleSession', {});
    }
}
