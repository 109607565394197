import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { SubscriberAppointmentNewComponent } from '../subscriber-appointment-new.component';

@Component({
    selector: 'app-subscriber-view-appointment-dialog',
    templateUrl: './subscriber-view-appointment-dialog.component.html',
    styleUrls: ['./subscriber-view-appointment-dialog.component.css']
})

export class SubscriberViewAppointmentDialogComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<SubscriberAppointmentNewComponent>,
        private subDataSvc: SubscriberDataNewService,
        public _sb: SnackbarService,
    ) { }

    ngOnInit() { }

    delete() {
        let body = {
            id: this.data.id,
        }
        this.subDataSvc.deleteAppointment(body).subscribe(res => {
            this._sb.openSnackBar("Appointment deleted");
            this.dialogRef.close()
        });
    }

    cancel() {
        let body = {
            id: this.data.id,
        }
        this.subDataSvc.cancelAppointment(body).subscribe(res => {
            this._sb.openSnackBar("Appointment cancelled");
            this.dialogRef.close()
        });
    }

    onNoClick() {
        this.dialogRef.close();
    }
}
