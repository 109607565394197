import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/auth.service';

@Component({
    selector: 'app-select-appointment-type-dialog',
    templateUrl: './select-appointment-type-dialog.component.html',
    styleUrls: ['./select-appointment-type-dialog.component.css']
})

export class SubsSelectAppointmentTypeDialogComponent implements OnInit {
    selected_val: string = '';
    selectAppointmentTypeForm: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private dialogRef: MatDialogRef<SubsSelectAppointmentTypeDialogComponent>,
        public auth: AuthService,
        private _fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.selectAppointmentTypeForm = this._fb.group({
            appointment_type: new FormControl('', Validators.required),
        });
    }

    close() {
        this.dialogRef.close();
    }

    onValChange(val: string) {
        this.selected_val = val;
    }

    selectAppointmentType() {
        this.dialogRef.close(this.selected_val);
    }
}
