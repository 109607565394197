import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SubscriberAppointments } from '../../interface/subscriber/subscriber-appointment.interface';
import { SubscriberViewAppointmentDialogComponent } from './subscriber-view-appointment-dialog/subscriber-view-appointment-dialog.component';
import { SubscriberVideoCallDialogComponent } from './subscriber-video-call-dialog/subscriber-video-call-dialog.component';
import { SubscriberAddAppointmentDialogComponent } from './subscriber-add-appointment-dialog/subscriber-add-appointment-dialog.component';
import { SubsSelectAppointmentTypeDialogComponent } from './select-appointment-type-dialog/select-appointment-type-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/common/error-dialog/error-dialog.component';

@Component({
    selector: 'app-subscriber-appointment-new',
    templateUrl: './subscriber-appointment-new.component.html',
    styleUrls: ['./subscriber-appointment-new.component.css']
})

export class SubscriberAppointmentNewComponent implements OnInit {
    private data: SubscriberAppointments[];
    dialogData: SubscriberAppointments;

    dataSource = new MatTableDataSource<SubscriberAppointments>();
    displayedColumns: string[] = ['date', 'time', 'status', 'actions'];
    pageSizeOptions: number[] = [5, 10, 20];
    pageSize = 100;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(
        private svc: SubscriberDataNewService,
        public dialog: MatDialog,
        private router: Router
    ) { }

    ngOnInit() {
        this.getSubAppts();
    }

    getSubAppts() {
        this.svc.getAppointmentData().subscribe((res) => {
            this.data = res.data;
            this.dataSource = new MatTableDataSource<SubscriberAppointments>(this.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        });
    }

    viewAppointment(id) {
        this.dialogData = this.data.find(item => item.id === id);

        const dialogRef = this.dialog.open(SubscriberViewAppointmentDialogComponent, {
            width: '400px',
            data: this.dialogData,
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getSubAppts();
        });
    }

    viewVideoCall(id) {
        this.dialogData = this.data.find(item => item.id === id);

        const dialogRef = this.dialog.open(SubscriberVideoCallDialogComponent, {
            width: '400px',
            data: this.dialogData,
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getSubAppts();
        });
    }

    selectAppointmentType() {
        let dialogRef = this.dialog.open(SubsSelectAppointmentTypeDialogComponent, {
            width: '400px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 1 || result == 2) {
                let tempComponent = SubscriberAddAppointmentDialogComponent as any;
                let data = {
                    appointment_type: result,
                    render_appointment: this.getSubAppts.bind(this),
                };

                if (result == 1) {
                    this.svc.getAppointmentCreditBalance().subscribe((res) => {
                        if (res.data <= 0) {
                            tempComponent = ErrorDialogComponent;
                            data['title'] = 'Insufficient Credits';
                            data['message'] = 'You have no credits left to add an appointment.';
                        }

                        dialogRef = this.dialog.open(tempComponent, {
                            width: '400px',
                            data: data,
                        });
                    });
                } else if (result == 2) {
                    this.svc.getInPersonCreateCheck().subscribe((res) => {
                        if (!res.data.createEnabled) {
                            tempComponent = ErrorDialogComponent;
                            data['title'] = 'Appointment Creation Disabled';
                            data['message'] = res.data.message;
                        }

                        dialogRef = this.dialog.open(tempComponent, {
                            width: '400px',
                            data: data,
                        });
                    });
                }

                dialogRef.afterClosed().subscribe((res) => {
                    this.getSubAppts();
                })
            }
        });
    }
}
