import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-help-support-new',
    templateUrl: './help-support-new.component.html',
    styleUrls: ['./help-support-new.component.css']
})

export class HelpSupportNewComponent implements OnInit {
    constructor() { }
    ngOnInit() { }
}
