import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
    selector: 'app-populate-profile-dialog',
    templateUrl: './populate-profile-dialog.component.html',
    styleUrls: ['./populate-profile-dialog.component.css']
})

export class PopulateProfileDialogComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<PopulateProfileDialogComponent>,
        private router: Router,
        public auth: AuthService,
    ) { }

    ngOnInit() { }

    profile() {
        if (this.auth.isDoctor()) {
            this.router.navigate(['/doc-profile']);
        } else if (this.auth.isSubscriber()) {
            this.router.navigate(['/sub-profile']);
        }
        this.dialogRef.close();
    }

    okay() {
        this.dialogRef.close();
    }
}
