import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { ForgotPasswordErrorDialogComponent } from '../forgot-password-error-dialog/forgot-password-error-dialog.component';
import { LoginPageComponent } from '../login-page.component';

@Component({
    selector: 'app-forgot-password-dialog',
    templateUrl: './forgot-password-dialog.component.html',
    styleUrls: ['./forgot-password-dialog.component.css']
})

export class ForgotPasswordDialogComponent implements OnInit {
    loading = false;
    forgotPasswordForm: FormGroup;

    constructor(
        private _fb: FormBuilder,
        private httpService: HttpService,
        private snackbarSvc: SnackbarService,
        public dialogRef: MatDialogRef<LoginPageComponent>,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.forgotPasswordForm = this._fb.group({
            email: new FormControl('', [Validators.required, Validators.email]),
        });
    }

    get f() { return this.forgotPasswordForm.controls; }

    onSubmit() {
        this.loading = true;
        this.httpService.commonPost(appConstants.apiBaseUrl + 'doForgotPassword', this.forgotPasswordForm.value).subscribe((res) => {
            if (res.status == false) {
                this.loading = false;
                let responseData = res.data;
                this.dialog.open(ForgotPasswordErrorDialogComponent, {
                    width: '300px',
                    data: { errorArr: responseData },
                })
            } else if (res.status == true) {
                this.dialogRef.close();
                this.loading = false;
                this.snackbarSvc.openSnackBar('Password reset mail sent successfully!');
            }
        })
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
