import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SpinnerService } from './spinner.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
    private excludeLoaderUrls: string[] = [
        'api/getWaitingSubscriber',
        'api/getTeleStatus',
        'api/notes/create',
        'api/notes/update',
        'api/getActiveTeleSession'
    ];

    constructor(
        private loaderService: SpinnerService
    ) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) this.requests.splice(i, 1);
        this.loaderService.visibility.next(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(req);
        if (!this.excludeLoaderUrls.some(url => req.url.includes(url))) this.loaderService.show();

        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) this.loaderService.hide();
        }, (error) => {
            this.loaderService.hide();
        }));
    }
}