import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { ConfirmDialogComponent } from 'src/app/components/common/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/common/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from 'src/app/components/common/success-dialog/success-dialog.component';
import { WaitingDialogComponent } from 'src/app/components/common/waiting-dialog/waiting-dialog.component';

@Component({
    selector: 'app-teleconsultation-dialog',
    templateUrl: './teleconsultation-dialog.component.html',
    styleUrls: ['./teleconsultation-dialog.component.css']
})

export class DocTeleconsultationDialogComponent implements OnInit {
    is_disabled: boolean = true;
    is_hidden: boolean = true;
    is_loading: boolean = true;
    is_available: boolean = false;
    is_selected: boolean = false;
    display_type: string = 'Please select a subscriber.';
    display_duration: string = 'Please select a subscriber.';

    subscribers: any[] = [];
    createTeleconsultationForm: FormGroup;
    confirmationDialog: MatDialogRef<ConfirmDialogComponent>;
    successDialog: MatDialogRef<SuccessDialogComponent>;
    waitDialog: MatDialogRef<WaitingDialogComponent>;
    isPaused: boolean = false;
    pollIntervalId: any;
    waitIntervalId: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private dialogRef: MatDialogRef<DocTeleconsultationDialogComponent>,
        private dialog: MatDialog,
        private router: Router,
        public auth: AuthService,
        private docDataService: DoctorDataService,
        public _fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.createTeleconsultationForm = this._fb.group({
            selected_subscriber: new FormControl('', Validators.required),
        });

        this.docDataService.getWaitingSubscriber().subscribe((res) => {
            if (res.data.length > 0) {
                this.is_disabled = false;
                this.is_hidden = false;
                res.data.forEach((item: any) => {
                    this.subscribers.push(item);
                });
            }
            this.is_loading = false;
        });

        this.docDataService.getTeleAvailability().subscribe((res) => {
            this.is_available = res.data;
        });
    }

    selectSubscriber() {
        this.close();
        let subscriber_id = this.createTeleconsultationForm.value.selected_subscriber;

        this.confirmationDialog = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            disableClose: true,
            data: {
                title: 'Accept Teleconsultation Request',
                message: 'You will be redirected to the video call page after accepting the teleconsultation request.',
                cancel_text: 'Reject',
                confirm_text: 'Accept',
            }
        });

        this.confirmationDialog.afterClosed().subscribe((res) => {
            if (res) {
                this.docDataService.joinTeleconsult({ sub_id: subscriber_id }).subscribe((res) => {
                    let appointmentId = res.data.appointment_id;
                    let sessionId = res.data.session_id;
                    let duration = res.data.duration;
                    let has_teleexam = res.data.has_teleexam;

                    if (res.status && appointmentId && sessionId) {
                        this.router.navigate(['/video-call'], {
                            queryParams: {
                                session_id: sessionId,
                                appointment_id: appointmentId,
                                duration: duration,
                                has_teleexam: has_teleexam,
                            }
                        });
                    } else {
                        this.dialog.open(ErrorDialogComponent, {
                            width: '400px',
                            data: {
                                title: 'Error',
                                message: res.data,
                            }
                        });
                    }
                });
            } else {
                this.docDataService.rejectTeleconsult({ sub_id: subscriber_id }).subscribe((res) => {
                    this.successDialog = this.dialog.open(SuccessDialogComponent, {
                        width: '400px',
                        data: {
                            title: 'Success',
                            message: 'Teleconsultation request rejected.',
                        }
                    });

                    this.successDialog.afterClosed().subscribe((res) => {
                        this.dialog.open(DocTeleconsultationDialogComponent, {
                            width: '600px',
                        });
                    });
                });
            }
        })
    }

    toggleAvailability() {
        this.is_available = !this.is_available;
        this.docDataService.updateTeleAvailability({ is_available: this.is_available }).subscribe((res) => {
            this.successDialog = this.dialog.open(SuccessDialogComponent, {
                width: '300px',
                data: {
                    title: 'Success',
                    message: 'Availability updated.',
                }
            });
        });
    }

    close() {
        this.dialogRef.close();
    }

    subscriberSelected(subscriber_id) {
        this.is_selected = false;
        this.display_type = 'Please select a subscriber.';
        this.display_duration = 'Please select a subscriber.';

        this.subscribers.find((item) => {
            if (item.id == subscriber_id) {
                this.is_selected = true;
                this.display_type = item.teleconsultation_type.name;
                this.display_duration = item.teleconsultation_type.duration + ' minutes';
            }
        });
    }
}
