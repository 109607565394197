import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MonthService } from 'src/app/util/date/month/month.service';

@Component({
    selector: 'app-period-picker-section',
    templateUrl: './period-picker.component.html',
    styleUrls: ['./period-picker.component.css'],
    providers: [MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule],
})

export class PeriodPickerSectionComponent implements OnInit {
    @Output() selectedPeriod = new EventEmitter();

    isDaily = true;
    isWeekly = false;
    isMonthly = false;

    // Params to display
    months: string[] = this.monthUtil.monthArr();
    years = this.getYears();
    weeks = this.getWeeksInYear(new Date().getFullYear());

    // Daily
    selectedDate = new Date();

    // Weekly
    selectedWeek = this.getCurrentWeek();
    selectedWeekYear = new Date().getFullYear();

    // Monthly
    selectedMonth = this.months[new Date().getMonth()];
    selectedMonthYear = new Date().getFullYear();

    // Params to pass to the API
    startDate = this.selectedDate;
    endDate = this.selectedDate;

    constructor(
        private monthUtil: MonthService,
    ) { }

    ngOnInit() {
        this.passDataToParent();
    }

    dateChanged(event: any) {
        this.selectedDate = event.value;
        this.passDataToParent();
    }

    weekChanged(event: any) {
        this.selectedWeek = event.value;
        this.passDataToParent();
    }

    weekYearChanged(event: any) {
        this.selectedWeekYear = event.value;
        this.weeks = this.getWeeksInYear(this.selectedWeekYear);
        this.passDataToParent();
    }

    monthChanged(event: any) {
        this.selectedMonth = event.value;
        this.passDataToParent();
    }

    monthYearChanged(event: any) {
        this.selectedMonthYear = event.value;
        this.passDataToParent();
    }

    togglePeriod(period: string) {
        if (period === 'day') {
            this.isDaily = true;
            this.isWeekly = false;
            this.isMonthly = false;
        } else if (period === 'week') {
            this.isDaily = false;
            this.isWeekly = true;
            this.isMonthly = false;
        } else if (period === 'month') {
            this.isDaily = false;
            this.isWeekly = false;
            this.isMonthly = true;
        }

        this.passDataToParent();
    }

    getWeeksInYear(year: number): { number: number, start: string, end: string }[] {
        const weeks: { number: number, start: string, end: string }[] = [];

        // Get the first day of the year
        const firstDay = new Date(year, 0, 1);

        // Loop through each week of the year
        let currentWeek = 1;
        let currentDate = new Date(firstDay.getTime());

        while (currentDate.getFullYear() === year) {
            const startOfWeek = new Date(currentDate.getTime());
            const endOfWeek = new Date(currentDate.getTime());

            // Find the end of the week (Sunday)
            endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay()));

            weeks.push({
                number: currentWeek,
                start: startOfWeek.toISOString().split('T')[0],
                end: endOfWeek.toISOString().split('T')[0]
            });

            // Move to the next week
            currentDate.setDate(currentDate.getDate() + 7);
            currentWeek++;
        }

        return weeks;
    }

    previousPeriod() {
        if (this.isDaily) {
            this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - 1));
        } else if (this.isWeekly) {
            if (this.selectedWeek > 1) {
                // If the selected week is not the first week of the year
                this.selectedWeek--;
            } else if (this.selectedWeekYear > this.years[0].value) {
                // If the selected week is the first week of the year, but the selected year is not the first year
                this.selectedWeekYear--;
                this.weeks = this.getWeeksInYear(this.selectedWeekYear);
                this.selectedWeek = this.weeks[this.weeks.length - 1].number;
            }
        } else if (this.isMonthly) {
            if (this.months.indexOf(this.selectedMonth) > 0) {
                // If selected month is not the first month of the year
                this.selectedMonth = this.months[this.months.indexOf(this.selectedMonth) - 1];
            } else if (this.selectedMonthYear > this.years[0].value) {
                // If selected month is the first month of the year, but the selected year is not the first year
                this.selectedMonthYear--;
                this.selectedMonth = this.months[this.months.length - 1];
            }
        }

        this.passDataToParent();
    }

    nextPeriod() {
        if (this.isDaily) {
            this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() + 1));
        } else if (this.isWeekly) {
            if (this.selectedWeek < this.weeks[this.weeks.length - 1].number) {
                // If the selected week is not the last week of the year
                this.selectedWeek++;
            } else if (this.selectedWeekYear < this.years[this.years.length - 1].value) {
                // If the selected week is the last week of the year, but the selected year is not the last year
                this.selectedWeekYear++;
                this.weeks = this.getWeeksInYear(this.selectedWeekYear);
                this.selectedWeek = 1;
            }
        } else if (this.isMonthly) {
            if (this.months.indexOf(this.selectedMonth) < this.months.length - 1) {
                // If selected month is not the last month of the year
                this.selectedMonth = this.months[this.months.indexOf(this.selectedMonth) + 1];
            } else if (this.selectedMonthYear < this.years[this.years.length - 1].value) {
                // If selected month is the last month of the year, but the selected year is not the last year
                this.selectedMonthYear++;
                this.selectedMonth = this.months[0];
            }
        }

        this.passDataToParent();
    }

    passDataToParent() {
        if (this.isDaily) {
            this.startDate = new Date(this.selectedDate);
            this.endDate = new Date(this.selectedDate);
        } else if (this.isWeekly) {
            const week = this.weeks.find(week => week.number === this.selectedWeek);
            this.startDate = new Date(week.start);
            this.endDate = new Date(week.end);
        } else if (this.isMonthly) {
            this.startDate = new Date(this.selectedMonthYear, this.months.indexOf(this.selectedMonth), 1);
            this.endDate = new Date(this.selectedMonthYear, this.months.indexOf(this.selectedMonth) + 1, 0);
        }

        this.selectedPeriod.emit({ startDate: this.startDate, endDate: this.endDate });
    }

    getCurrentWeek() {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), 0, 1);
        const pastDaysOfYear = (today.getTime() - firstDay.getTime()) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDay.getDay() + 1) / 7);
    }

    getYears() {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let i = 2016; i <= currentYear; i++) {
            years.push({ value: i, viewValue: i });
        }

        return years;
    }
}
