import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth.service";
import appConstants from "src/app/config/app.constants";
import { HttpService } from "src/app/services/http.service";
import { SnackbarService } from "src/app/util/snackbar/snackbar.service";
import { ForgotPasswordDialogComponent } from "./forgot-password-dialog/forgot-password-dialog.component";

@Component({
    selector: "app-login-page",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.css"]
})

export class LoginPageComponent implements OnInit {
    public loginForm: FormGroup;

    constructor(
        private httpService: HttpService,
        private fb: FormBuilder,
        private router: Router,
        private auth: AuthService,
        public dialog: MatDialog,
        private snackbarSvc: SnackbarService
    ) { }

    ngOnInit() {
        this.loginForm = this.fb.group({
            username: new FormControl("", [Validators.required, Validators.email]),
            password: new FormControl("", [
                Validators.required,
                Validators.minLength(8)
            ])
        });
    }

    get f() {
        return this.loginForm.controls;
    }

    login() {
        this.httpService
            .commonPost(appConstants.apiBaseUrl + "doLogin", this.loginForm.value)
            .subscribe(res => {
                const loginResponse = res;
                if (
                    loginResponse.data.category === appConstants.userType.SUBSCRIBER ||
                    loginResponse.data.category === appConstants.userType.DOCTOR
                ) {
                    this.auth.setLoginDetails(loginResponse.data);
                    this.auth.setLoginToken(loginResponse.authenticate);
                    this.snackbarSvc.openSnackBar("You are now logged in");
                    this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
                } else if (loginResponse.status == false) {
                    this.snackbarSvc.openSnackBar(loginResponse.data[0]);
                } else {
                    this.snackbarSvc.openSnackBar("User Does Not Exist!");
                }
            });
    }

    signup() {
        this.router.navigate([appConstants.routingList.SIGNUP_COMPONENT]);
    }

    forgotPassword() {
        this.dialog.open(ForgotPasswordDialogComponent, {
            width: "300px"
        });
    }
}
