import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { mergeMap } from 'rxjs/operators';
import appConstants from 'src/app/config/app.constants';

@Component({
    selector: 'app-purchase-credit-dialog',
    templateUrl: './purchase-credit-dialog.component.html',
    styleUrls: ['./purchase-credit-dialog.component.css']
})

export class PurchaseCreditDialogComponent implements OnInit {
    is_disabled: boolean = true;
    is_loading: boolean = true;
    is_hidden: boolean = true;
    total_payment: number = 0.00;
    amountInput: number = 1;
    price_per_credit: number;

    // ePaymentForm Params
    paymentURL: string = "https://payment.ipay88.com.my/ePayment/entry.asp";
    refNum: string;
    amount: string;
    fullname: string;
    email: string;
    signature: string;
    curr: string = "MYR";
    merchantCode: string = "M28617";
    contactNumber: string;
    lang: string = "UTF-8";
    signatureType: string = "SHA256";
    responseURL: string;
    backendURL: string;
    prodDesc: string = "Credit Purchase";
    remark: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private dialogRef: MatDialogRef<PurchaseCreditDialogComponent>,
        private router: Router,
        public auth: AuthService,
        private dataService: SubscriberDataNewService,
        public _sb: SnackbarService,
    ) { }

    ngOnInit() {
        let APIBaseURL = appConstants.apiBaseUrl.replace(/api\/$/, "");
        let baseURL = window.location.origin;

        this.responseURL = `${APIBaseURL}payment/response`;
        this.backendURL = `${APIBaseURL}payment/backend`;
        this.remark = `${baseURL}/#/request-success|${baseURL}/#/request-error`;

        this.getPackageList();
        this.setBillingInfo();
    }

    getPackageList() {
        let params = { type: 'credit' };

        this.dataService.getPackageList(params).subscribe((res) => {
            if (Array.isArray(res.data) && res.data.length) {
                this.is_disabled = false;
                this.is_hidden = false;

                let firstPackage = res.data[0];
                this.price_per_credit = firstPackage.cost;

                this.calculateTotalPayment(this.amountInput);
            }
            this.is_loading = false;
        });
    }

    purchaseCredit() {
        this.is_disabled = true;
        let params = { quantity: this.amountInput };

        this.dataService.purchaseCredit(params).pipe(
            mergeMap((res) => {
                if (res.status) {
                    this.refNum = res.data.order_id;
                    this.amount = res.data.amount;

                    let params = {
                        signature_type: this.signatureType,
                        merchant_code: this.merchantCode,
                        transaction_id: this.refNum,
                        amount: this.amount,
                        currency: this.curr,
                    };

                    return this.dataService.generateSignature(params);
                } else {
                    this._sb.openSnackBar(res.data);
                    this.is_disabled = false;
                    throw new Error('Error in purchaseCredit');
                }
            })
        ).subscribe((res) => {
            if (res.status) {
                this.signature = res.data;
                this.close();

                const ePaymentForm = document.getElementsByName("ePayment")[0] as HTMLFormElement | null;
                if (ePaymentForm) ePaymentForm.submit();
            }
            this.is_disabled = false;
        }, (error) => {
            this.is_disabled = false;
        });
    }

    onAmountInputChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.amountInput = parseInt(target.value);
        this.is_disabled = this.amountInput ? false : true;
        this.calculateTotalPayment(this.amountInput);
    }

    calculateTotalPayment(quantity: number) {
        let tempTotalPayment = this.price_per_credit * (quantity ? quantity : 0);
        tempTotalPayment = parseFloat(tempTotalPayment.toFixed(2));
        this.total_payment = tempTotalPayment;
    }

    setBillingInfo() {
        this.dataService.getMyAccountInfo().subscribe((res) => {
            this.fullname = res.data.firstname + ' ' + res.data.lastname;
            this.email = res.data.email;
            this.contactNumber = res.data.mobile;
        });
    }

    close() {
        this.dialogRef.close();
    }
}