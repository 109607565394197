import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import appConstants from 'src/app/config/app.constants';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { HttpService } from 'src/app/services/http.service';
import { ErrorSignupDialogComponent } from './error-signup-dialog/error-signup-dialog.component';
import { VerifyEmailDialogComponent } from './verify-email-dialog/verify-email-dialog.component';

export interface Category {
    value: number,
    viewValue: string;
}

@Component({
    selector: 'app-singup-page',
    templateUrl: './singup-page.component.html',
    styleUrls: ['./singup-page.component.css']
})

export class SingupPageComponent implements OnInit {
    confirmHide;
    hide;
    signupForm: FormGroup;

    categories: Category[] = [
        { value: 1, viewValue: 'Doctor' },
        { value: 2, viewValue: 'Subscriber' },
    ]

    constructor(
        private _fb: FormBuilder,
        private httpService: HttpService,
        private router: Router,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.signupForm = this._fb.group({
            firstname: new FormControl('', [Validators.required, Validators.minLength(2)]),
            lastname: new FormControl('', [Validators.required, Validators.minLength(4)]),
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
            password2: new FormControl('', [Validators.required]),
            category: new FormControl('', [Validators.required]),
        }, {
            validator: MustMatch('password', 'password2')
        })
    }

    get f() { return this.signupForm.controls; }

    onSubmit() {
        this.httpService.commonPost(appConstants.apiBaseUrl + 'doRegister', this.signupForm.value).subscribe((res) => {
            if (res.status == false) {
                let responseData = res.data;
                let dialogRef = this.dialog.open(ErrorSignupDialogComponent, {
                    width: '300px',
                    data: { errorArr: responseData },
                })
            } else if (res.status == true) {
                this.openDialog();
            }
        });
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(VerifyEmailDialogComponent, {
            width: '250px',
        });
        dialogRef.afterClosed().subscribe(result => {
            this.router.navigate([appConstants.routingList.LOGIN_COMPONENT]);
        });
    }

    back() {
        this.router.navigate([appConstants.routingList.LOGIN_COMPONENT]);
    }
}
