import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { SubscriberAppointmentNewComponent } from '../subscriber-appointment-new.component';

@Component({
    selector: 'app-subscriber-video-call-dialog',
    templateUrl: './subscriber-video-call-dialog.component.html',
    styleUrls: ['./subscriber-video-call-dialog.component.css']
})

export class SubscriberVideoCallDialogComponent implements OnInit {
    disable_vc: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<SubscriberAppointmentNewComponent>,
        private subDataSvc: SubscriberDataNewService,
        public _sb: SnackbarService,
        private router: Router,
    ) { }

    ngOnInit() {
        if (new Date(this.data.appointment_datetime) >= new Date() || this.data.status !== '3' || !this.data.session_id) {
            this.disable_vc = true;
        }
    }

    joinAppointment() {
        if (new Date(this.data.appointment_datetime) >= new Date() || this.data.status !== '3' || !this.data.session_id) {
            this._sb.openSnackBar('Video call details are invalid');
            return;
        }

        this.dialogRef.close();
        this.router.navigate(['/video-call'], { queryParams: {
            session_id: this.data.session_id,
            appointment_id: this.data.id,
            duration: this.data.duration,
            has_teleexam: this.data.has_teleexam,
        } });
    }
}
