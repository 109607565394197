import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
    selector: 'app-waiting-dialog',
    templateUrl: './waiting-dialog.component.html',
    styleUrls: ['./waiting-dialog.component.css']
})

export class WaitingDialogComponent implements OnInit {
    title: string = 'Please Wait';
    message: string = 'Please wait while we process your request.';
    cancel_text: string = 'Cancel Request';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private dialogRef: MatDialogRef<WaitingDialogComponent>,
        private router: Router,
        public auth: AuthService,
    ) { }

    ngOnInit() {
        if (this.data) {
            if (this.data.title) this.title = this.data.title;
            if (this.data.message) this.message = this.data.message;
            if (this.data.cancel_text) this.cancel_text = this.data.cancel_text;
        }
    }

    cancelRequest(status: boolean) {
        this.dialogRef.close(status);
    }
}
