import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { DoctorAppointmentInterface } from '../../interface/doctor/doctor-appointmen.interface';
import { DoctorAppointmentsViewComponent } from './doctor-appointments-view/doctor-appointments-view.component';
import { DoctorVideoCallView } from './doctor-video-call-view/doctor-video-call-view.component';
import { first } from 'rxjs/operators';
import { IsoDateService } from 'src/app/util/date/iso-date.service';

@Component({
    selector: 'app-doctor-appointments',
    templateUrl: './doctor-appointments.component.html',
    styleUrls: ['./doctor-appointments.component.css']
})

export class DoctorAppointmentsComponent implements OnInit {
    private data;

    dataSource = new MatTableDataSource<DoctorAppointmentInterface>();
    displayedColumns: string[] = ['sub_id', 'subscriber_name', 'appointment_datetime', 'status', 'actions'];
    pageSizeOptions: number[] = [5, 10, 20];
    pageSize = 100;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(
        private doctorDataSvc: DoctorDataService,
        public dialog: MatDialog,
        private isoDate: IsoDateService,
    ) { }

    ngOnInit() {
        this.getAppointments();
    }

    getAppointments() {
        this.doctorDataSvc.getAppointments().subscribe((res) => {
            this.data = res.data;
            this.dataSource = new MatTableDataSource<DoctorAppointmentInterface>(this.data);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        })
    }

    viewAppointment(id) {
        let selectedAppointment = this.data.find(item => item.id == id);
        this.openDialog(selectedAppointment);
    }

    viewVideoCall(id) {
        let selectedAppointment = this.data.find(item => item.id === id);

        const dialogRef = this.dialog.open(DoctorVideoCallView, {
            width: '400px',
            data: selectedAppointment,
        });

        dialogRef.afterClosed().pipe(first()).subscribe(res => {
            this.getAppointments();
        })
    }

    openDialog(dialog_data) {
        let dialogRef = this.dialog.open(DoctorAppointmentsViewComponent, {
            width: '300px',
            data: dialog_data
        });

        dialogRef.afterClosed().pipe(first()).subscribe(res => {
            this.getAppointments();
        })
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
}
