import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from '../http.service';

@Injectable({
    providedIn: 'root'
})

export class DoctorDataService {
    private sub_data = new BehaviorSubject({});
    private httpService: HttpService;

    constructor(
        httpService: HttpService,
    ) { this.httpService = httpService; }

    getSubList() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getSubscriberList', {});
    }

    postSub(data) {
        this.sub_data.next(data);
    }

    getSub() {
        return this.sub_data.asObservable();
    }

    getMedicalSummaryId(id) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyMedicalSummary', {
            sub_id: stringify(id),
        });
    }

    getSignificantMedicalHistoryList() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getSignificantMedicalHistoryList', {});
    }

    getPastSurgicalHistoryList() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'gePastSurgicalHistoryList', {});
    }

    getMedicationList() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMedicationList', {});
    }

    getDrugAllergyList() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getDrugAllergyList', {});
    }

    updateMedicalSummary(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'updateMedicalSummary', params);
    }

    getNotes(params = {}) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'notes/list', params);
    }

    deleteNotes(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'notes/remove', params);
    }

    updateNotes(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'notes/update', params);
    }

    createNotes(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'notes/create', params);
    }

    getAppointments() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getAppointmentByDoctor', {});
    }

    addAppointment(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'addAppointment', params);
    }

    getTransaction(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getTransaction', params);
    }

    doClaimTransaction(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'doClaimTransaction', params);
    }

    getClaimListing(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getClaimListing', params);
    }

    getVitalList() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getVitalList', {});
    }

    getLatestDeviceData(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getLatestDeviceData', params);
    }

    addUpdateInitialReport(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'addUpdateInitialReport', body);
    }

    getSubscriberInitialTarget(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getSubscriberInitialTarget', params);
    }

    createNewTarget(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'createNewTarget', body);
    }

    createTarget(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'createTarget', body);
    }

    updateTarget(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'updateTarget', body);
    }

    getMyEmpanelment() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyEmpanelment', {});
    }

    confirmAppointment(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'confirmAppointment', body);
    }

    rejectAppointment(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'rejectAppointment', body);
    }

    cancelAppointment(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'cancelAppointment', body);
    }

    completeAppointment(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'completeAppointment', body);
    }

    doInviteSubscriber(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'doInviteSubscriber', body);
    }

    getWaitingSubscriber() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getWaitingSubscriber', {});
    }

    joinTeleconsult(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'joinTeleconsult', body);
    }

    rejectTeleconsult(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'rejectTeleconsult', body);
    }

    updateTeleAvailability(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'updateTeleAvailability', body);
    }

    getTeleAvailability() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getTeleAvailability', {});
    }

    getDeviceData(params: any, useCI2 = false) {
        let apiURL = useCI2 ? appConstants.apiBaseUrlCI2 : appConstants.apiBaseUrl;
        return this.httpService.commonAuthPost(apiURL + 'getDeviceData', params);
    }

    getPhysicianList() {
        return this.httpService.commonAuthGet(appConstants.apiBaseUrl + 'physician', {});
    }

    getMyAccountInfo() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyAccount', {});
    }

    updateMyAccountInfo(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'updateMyAccount', body);
    }
}
