import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MedicalSummaryNewComponent } from 'src/app/components/common/medical-summary-new/medical-summary-new.component';
import { MedicalConditions } from 'src/app/components/interface/common/medical-summary-interface/medical-summary-conditions.interface';

@Component({
    selector: 'app-add-item-dialog',
    templateUrl: './add-item-dialog.component.html',
    styleUrls: ['./add-item-dialog.component.css']
})

export class AddItemDialogComponent implements OnInit {
    dataList: MedicalConditions[] = [];
    title: string = "";
    isDesc: boolean = true;
    public medicalForm: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<MedicalSummaryNewComponent>,
        public _fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.medicalForm = this._fb.group({
            id: new FormControl('', [Validators.required]),
            since: new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(4), Validators.minLength(4)])
        });

        this.dataList = this.data.list;
        this.title = this.data.name;
        if (this.title == 'Current Medication' || this.title == 'Drug Allergies') this.isDesc = false;
    }

    addItem() {
        let selectedName = this.dataList.filter(item => item.id == this.medicalForm.value.id);
        const tempName = this.isDesc ? selectedName[0].desc : selectedName[0].name;
        this.dialogRef.close({ name: tempName, since: this.medicalForm.value.since });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    get f() { return this.medicalForm.controls };
}
