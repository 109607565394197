import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from 'src/app/services/http.service';
import { clinicTypes } from '../../../../assets/lists/clinicTypes';
import { DatePipe } from '@angular/common';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';

@Component({
    selector: 'app-doctor-profile-new',
    templateUrl: './doctor-profile-new.component.html',
    styleUrls: ['./doctor-profile-new.component.css']
})

export class DoctorProfileNewComponent implements OnInit {
    public doctorProfileForm: FormGroup;
    public clinicTypes = clinicTypes;
    public physicianList = [];
    public generalPractitioner;

    constructor(
        private doctorDataSvc: DoctorDataService,
        private httpService: HttpService,
        private _fb: FormBuilder,
        private router: Router,
        private datepipe: DatePipe,
        private openSnackBar: SnackbarService,
    ) {
        this.doctorProfileForm = this._fb.group({
            user_id: new FormControl({ value: '', disabled: true }, Validators.required),
            firstname: new FormControl({ value: '', disabled: false }, Validators.required),
            lastname: new FormControl({ value: '', disabled: false }, Validators.required),
            email: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.email]),
            dob: new FormControl({ value: '', disabled: false }, Validators.required),
            nationality: new FormControl({ value: '', disabled: false }, Validators.required),
            gender: new FormControl({ value: '', disabled: false }, Validators.required),
            address: new FormControl({ value: '', disabled: false }, Validators.required),
            address2: new FormControl({ value: '', disabled: false }),
            postcode: new FormControl({ value: '', disabled: false }, Validators.required),
            state: new FormControl({ value: '', disabled: false }, Validators.required),
            country: new FormControl({ value: '', disabled: false }, Validators.required),
            mobile: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.maxLength(10), Validators.pattern('[0-9]*')]),
            phone_no: new FormControl({ value: '', disabled: false }, Validators.required),
            fax_no: new FormControl({ value: '', disabled: false }, Validators.required),
            provider: new FormControl({ value: '', disabled: false }, Validators.required),
            provider_license_no: new FormControl({ value: '', disabled: false }, Validators.required),
            medical_reg_no: new FormControl({ value: '', disabled: false }, Validators.required),
            reg_year: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.maxLength(4), Validators.pattern('[0-9]*')],),
            mma_no: new FormControl({ value: '', disabled: false }, Validators.required),
            clinic_type: new FormControl({ value: '', disabled: false }, Validators.required),
            clinic_type_desc: new FormControl({ value: '', disabled: false }, Validators.required),
            physician_id: new FormControl({ value: '', disabled: false }, Validators.required),
        });
    }

    ngOnInit() {
        this.initAccountDetails();
        this.initPhysicianList();
    }

    async initAccountDetails() {
        const res = await this.doctorDataSvc.getMyAccountInfo().toPromise();
        const details = res.data;

        this.doctorProfileForm.patchValue({
            user_id: details.id,
            firstname: details.firstname,
            lastname: details.lastname,
            email: details.email,
            dob: details.dob,
            nationality: details.nationality,
            gender: details.gender,
            address: details.address,
            address2: details.address2,
            postcode: details.postcode,
            state: details.state,
            country: details.country,
            mobile: details.mobile,
            phone_no: details.phone_no, // TODO: Check if this field is required, seems like it is redundant with mobile
            fax_no: details.fax_no,
            provider: details.provider,
            provider_license_no: details.provider_license_no,
            medical_reg_no: details.medical_reg_no,
            reg_year: details.reg_year,
            mma_no: details.mma_no,
            clinic_type: details.physician_name.toLowerCase() == 'general practitioner' ? "1" : "2",
            clinic_type_desc: details.clinic_type_desc,
            physician_id: details.physician_id
        });

        this.clinicTypeChange({ value: this.doctorProfileForm.controls['clinic_type'].value });
        // Questions: medical reg no vs mma no, which one is required and which one is mmc no?
    }

    async initPhysicianList() {
        const res = await this.doctorDataSvc.getPhysicianList().toPromise();
        if (res.status) {
            res.data.forEach(element => {
                // Skip General Practitioner
                if (element.name.toLowerCase() == 'general practitioner') {
                    this.generalPractitioner = element;
                    return;
                }
                this.physicianList.push({ id: element.id, name: element.name });
            });

            // Sort Physician List
            this.physicianList.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
            });
        }
    }

    clinicTypeChange(event: { value: any; }) {
        const physicianControl = this.doctorProfileForm.controls['physician_id'];
        if (event.value == 1) {
            physicianControl.disable();
            physicianControl.reset();
        } else {
            physicianControl.enable();
        }
    }

    async onSubmit() {
        let profileValues = this.doctorProfileForm.value;

        profileValues.dob = this.datepipe.transform(profileValues.dob, ('yyyy-MM-dd'));
        profileValues.reg_year = this.datepipe.transform(profileValues.reg_year, ('yyyy'));
        profileValues.gender = this.genderToID(profileValues.gender);

        // If clinic type is general practitioner, physician should be general practitioner
        if (profileValues.clinic_type == 1) {
            profileValues.physician_id = this.generalPractitioner.id;
        }

        const res = await this.doctorDataSvc.updateMyAccountInfo(profileValues).toPromise();
        if (res.status) {
            this.openSnackBar.openSnackBar('Profile Data Updated Successfully');
        } else {
            this.openSnackBar.openSnackBar('Profile Data failed to update, check console for error message');
        }

        this.router.navigate([appConstants.routingList.HOME_COMPONENT]);

        // Nationality should be a dropdown instead of text field
        // State should be a dropdown instead of text field
        // Country should be a dropdown instead of text field
    }

    genderToID(val: string) {
        if (val == 'Male') return 1;
        else if (val == 'Female') return 2;
        else return null;
    }

    get f() { return this.doctorProfileForm.controls; }
}
