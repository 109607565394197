// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  // apiUrl: 'https://testing.ourcheckup.com/api/',
  // apiUrlCI2: 'https://staging.ourcheckup.com/api/', // Temporary solution to get textfile device data

  // apiUrl: 'http://localhost:8081/api/',
  // apiUrlCI2: 'http://localhost:8081/api/', // Temporary solution to get textfile device data

  apiUrl: 'https://imc.ourcheckup.com/api/',
  apiUrlCI2: 'https://imc.ourcheckup.com/api/',

  apiTokBoxKey: '47672111',

  // Default firebase config
  // firebase: {
  //   apiKey: "AIzaSyDSHZfAvf_C582hMtzYcSZayBpJAmH1iFw",
  //   authDomain: "ourcheckup-cdace.firebaseapp.com",
  //   databaseURL: "https://ourcheckup-cdace.firebaseio.com",
  //   projectId: "ourcheckup-cdace",
  //   storageBucket: "ourcheckup-cdace.appspot.com",
  //   messagingSenderId: "128200139453",
  //   appId: "1:128200139453:web:a61ad859ecaf68a4368dd4",
  //   measurementId: "G-6EL90HRYV4",
  // },

  // IMC firebase config
  firebase: {
    apiKey: "AIzaSyArMkUVvi7ZoenFNGE3is_GNFXOFuyLw1k",
    authDomain: "iheal-1a048.firebaseapp.com",
    projectId: "iheal-1a048",
    storageBucket: "iheal-1a048.appspot.com",
    messagingSenderId: "375616810897",
    appId: "1:375616810897:web:43c8786c3418e63d747430",
    measurementId: "G-891H76CMY4",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
