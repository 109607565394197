import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import appConstants from 'src/app/config/app.constants';
import { EncryptionService } from 'src/app/services/encryption.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { SubscriberOrderNewComponent } from '../subscriber-order-new.component';

@Component({
	selector: 'app-confirm-order-dialog',
	templateUrl: './confirm-order-dialog.component.html',
	styleUrls: ['./confirm-order-dialog.component.css']
})

export class ConfirmOrderDialogComponent implements OnInit {
	// ePaymentForm Params
    paymentURL: string = "https://payment.ipay88.com.my/ePayment/entry.asp";
	refNum: string;
	amount: string;
	fullname: string;
	email: string;
	signature: string;
	curr: string = "MYR";
	merchantCode: string = "M28617";
	contactNumber: string = "";
	lang: string = "UTF-8";
	signatureType: string = "SHA256";
    responseURL: string;
    backendURL: string;
    prodDesc: string = "Package Subscription";
    remark: string;

	constructor(
		public dialogRef: MatDialogRef<SubscriberOrderNewComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		public snackbarSvc: SnackbarService,
		private dataService: SubscriberDataNewService,
		private router: Router,
		private encrypt: EncryptionService,
		public http: HttpClient,
		public currency: CurrencyPipe,
	) { }

	ngOnInit() {
		let APIBaseURL = appConstants.apiBaseUrl.replace(/api\/$/, "");
        let baseURL = window.location.origin;

        this.responseURL = `${APIBaseURL}payment/response`;
        this.backendURL = `${APIBaseURL}payment/backend`;
        this.remark = `${baseURL}/#/request-success|${baseURL}/#/request-error`;

		this.refNum = this.data.id;
		this.email = this.data.email;
		this.fullname = this.data.fullname;

		let amt = this.data.total;
		let curr = "MYR";
		let regex = /[.,\s]/g;
		let amount = amt.replace(regex, '')

		this.amount = this.currency.transform(amt, " ").trim();
		let params = {
			signature_type: 'SHA256',
			merchant_code: appConstants.payment.MERCHANT_CODE,
			transaction_id: this.refNum,
			amount: amount,
			currency: curr,
		};

		this.dataService.generateSignature(params).subscribe((res) => {
			this.signature = res.data;
		});
	}

	onSubmit() {
		const ePaymentForm = document.getElementsByName("ePayment")[0] as HTMLFormElement | null;
		if (ePaymentForm) ePaymentForm.submit();
	}

	onConfirmClick() {
		this.dataService.confirmOrder({
			transaction_id: this.data.id, //user id is used as the transaction id
			payment_reference: "", //???
			payment_status: "success" //somehow query the response from ipay88
		}).subscribe((res) => {
			this.snackbarSvc.openSnackBar('Payment Sucessful');
			this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
			this.dialogRef.close();
		}, (err) => {
			this.snackbarSvc.openSnackBar('There was a problem. Payment Failed');
			console.log(err);
			this.dialogRef.close();
		});
	}

	onCancelClick() { this.dialogRef.close(); }
}
