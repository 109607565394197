import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
    selector: 'app-success-dialog',
    templateUrl: './success-dialog.component.html',
    styleUrls: ['./success-dialog.component.css']
})

export class SuccessDialogComponent implements OnInit {
    is_success: boolean = true; // Default is success dialog
    is_info: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private dialogRef: MatDialogRef<SuccessDialogComponent>,
        private router: Router,
        public auth: AuthService,
    ) { }

    ngOnInit() {
        if (this.data) {
            if (this.data.title) document.querySelector('#title').innerHTML = this.data.title;
            if (this.data.message) document.querySelector('#message').innerHTML = this.data.message;
            if (this.data.is_info) {
                this.is_info = true;
                this.is_success = false;
            }
        }
    }

    close() {
        this.dialogRef.close();
    }
}
