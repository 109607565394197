import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import { AuthService } from 'src/app/auth.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
    selector: 'app-medical-notes-bottom-sheet',
    templateUrl: './medical-notes-bottom-sheet.component.html',
    styleUrls: ['./medical-notes-bottom-sheet.component.css'],
})

export class MedicalNotesBottomSheet implements OnInit {
    healthData: any;
    newsInput: string;
    noteInput: string;
    isOxygenChecked: boolean;
    isAlertnessChecked: boolean;

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data,
        private bottomSheet: MatBottomSheetRef,
        private auth: AuthService,
        private httpService: HttpService,
    ) { }

    ngOnInit() {
        if (this.data) {
            if (this.data.healthData) this.healthData = this.data.healthData;

            if (this.data.newsInput) this.newsInput = this.data.newsInput;
            if (this.data.noteInput) this.noteInput = this.data.noteInput;
            if (this.data.isOxygenChecked) this.isOxygenChecked = this.data.isOxygenChecked;
            if (this.data.isAlertnessChecked) this.isAlertnessChecked = this.data.isAlertnessChecked;

            if (this.data.showNEWSScore) this.showNEWSScore = this.data.showNEWSScore;
            if (this.data.calculateNEWsScore) this.calculateNEWsScore = this.data.calculateNEWsScore;
            if (this.data.toggleSupplementalOxygen) this.toggleSupplementalOxygen = this.data.toggleSupplementalOxygen;
            if (this.data.toggleAlertness) this.toggleAlertness = this.data.toggleAlertness;
            if (this.data.getHealthData) this.getHealthData = this.data.getHealthData;
        }
    }

    saveNotes() {
        const newsInput = document.getElementById('news-input') as HTMLInputElement | null;
        const noteInput = document.getElementById('note-input') as HTMLInputElement | null;

        this.newsInput = newsInput.value;
        this.noteInput = noteInput.value;

        const returnData = {
            newsInput: this.newsInput,
            noteInput: this.noteInput,
            isOxygenChecked: this.isOxygenChecked,
            isAlertnessChecked: this.isAlertnessChecked,
        };

        this.bottomSheet.dismiss(returnData);
    }

    showNEWSScore() {}
    calculateNEWsScore() {}
    toggleSupplementalOxygen() {}
    toggleAlertness() {}
    getHealthData() {}
}