import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';

@Component({
    selector: 'app-respiratory-rate-table',
    templateUrl: './respiratory-rate.component.html',
    styleUrls: ['./respiratory-rate.component.css'],
})

export class RespiratoryRateTableComponent implements OnInit {
    dateFrom: string;
    dateTo: string;

    subscriberId: number = null;
    category: string = 'Respiratory Rate';

    dataSource: any[] = [];
    displayedColumns: string[] = ['date', 'time', 'rate'];

    constructor(
        private subDataSvc: SubscriberDataNewService,
        private docDataSvc: DoctorDataService,
        private auth: AuthService,
    ) { }

    ngOnInit() { }

    async getDeviceData(period: { startDate: Date, endDate: Date }) {
        this.dateFrom = period.startDate.toISOString().split('T')[0];
        this.dateTo = period.endDate.toISOString().split('T')[0];

        let params = {
            category: this.category,
            date_from: this.dateFrom,
            date_to: this.dateTo,
        };

        let dataService = null;

        if (this.auth.isDoctor()) {
            if (!this.subscriberId) {
                const res = await this.docDataSvc.getSub().pipe(first()).toPromise();
                this.subscriberId = Object(res).id;
            }

            params['sub_id'] = this.subscriberId;
            dataService = this.docDataSvc.getDeviceData(params);
        } else if (this.auth.isSubscriber()) {
            dataService = this.subDataSvc.getDeviceData(params);
        }

        if (dataService) {
            const res = await dataService.toPromise();
            this.dataSource = this.processData(res.data);
        }
    }

    processData(apiData: any) {
        let result = [];

        // Transform data into the desired format
        apiData.forEach((item) => {
            const date = item.date.split(' ')[0];
            const time = item.date.split(' ')[1];

            const existingData = result.find((data) => data.date === date);
            if (existingData) {
                existingData.data.push({ value: parseInt(item.respiratory_rate), time });
            } else {
                result.push({
                    date,
                    data: [{ value: parseInt(item.respiratory_rate), time }]
                });
            }
        });

        // Sort the data by time for each date
        result.forEach((data) => {
            data.data.sort((a, b) => {
                return b.time.localeCompare(a.time);
            });
        });

        // Sort the result by date
        result.sort((a, b) => {
            return b.date.localeCompare(a.date);
        });

        return result;
    }
}
