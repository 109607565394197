import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/components/common/confirm-dialog/confirm-dialog.component';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';

@Component({
    selector: 'app-doctor-note-view',
    templateUrl: './doctor-note-view.component.html',
    styleUrls: ['./doctor-note-view.component.css']
})

export class DoctorNoteViewDialogComponent implements OnInit {
    note: any;
    content: any;
    isEditChecked: boolean = false;
    createMode: boolean = false;
    confirmationDialog: MatDialogRef<ConfirmDialogComponent>;
    title: string = 'Note Details';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private dialogRef: MatDialogRef<DoctorNoteViewDialogComponent>,
        private dialog: MatDialog,
        private snackBarSvc: SnackbarService,
        private doctorDataSvc: DoctorDataService,
    ) { }

    ngOnInit() {
        if (this.data) {
            this.note = this.data;
            this.content = this.note.content;
            console.log('Edit Mode');
        } else {
            this.createMode = true;
            this.title = 'Create Note';
            console.log('Create Mode');
        }
    }

    toggleEdit() {
        this.isEditChecked = !this.isEditChecked;
        this.isEditChecked ? this.title = 'Edit Note' : this.title = 'Note Details';
    }

    onNoClick() {
        this.dialogRef.close();
    }

    onSave() {
        this.createMode ? this.createNote() : this.updateNote();
    }

    onDelete() {
        this.confirmationDialog = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            disableClose: true,
            data: {
                title: 'Delete Note',
                message: 'Are you sure you want to delete this note?',
                cancel_text: 'Cancel',
                confirm_text: 'Delete',
            }
        });

        this.confirmationDialog.afterClosed().subscribe((res) => {
            if (res) {
                this.doctorDataSvc.deleteNotes({ id: this.note.id }).subscribe((res) => {
                    this.snackBarSvc.openSnackBar("Note deleted successfully");
                    this.dialogRef.close();
                });
            }
        });
    }

    updateNote() {
        let content = document.getElementById('note-content') as HTMLTextAreaElement;

        this.doctorDataSvc.updateNotes({
            id: this.note.id,
            type: this.note.type,
            content: content.value
        }).subscribe((res) => {
            this.snackBarSvc.openSnackBar("Note updated successfully");
            this.dialogRef.close();
        });
    }

    createNote() {
        let content = document.getElementById('note-content') as HTMLTextAreaElement;
        console.log(content.value);

        this.doctorDataSvc.createNotes({
            type: "DN",
            content: content.value
        }).subscribe((res) => {
            this.snackBarSvc.openSnackBar("Note created successfully");
            this.dialogRef.close();
        });
    }
}
