import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';
import 'rxjs/add/operator/take';
import { BehaviorSubject } from 'rxjs';
import appConstants from './config/app.constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class MessagingService {
    headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    options = { headers: this.headers };
    currentMessage = new BehaviorSubject(null)
    messaging;

    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
        private afAuth: AngularFireAuth
    ) { }

    isSupported() {
        if (firebase.messaging.isSupported()) {
            this.messaging = firebase.messaging();
            return true;
        } else {
            return false;
        }
    }

    getPermission() {
        this.messaging.requestPermission().then(() => {
            // Notification permission granted.
            return this.messaging.getToken()
        }).then(token => {
            console.log(token);
            this.updateFCMToken(token);
        }).catch((err) => {
            // Unable to get permission to notify.
        });
    }

    receiveMessage() {
        this.messaging.onMessage((payload) => {
            this.currentMessage.next(payload)
        });
    }

    updateFCMToken(token) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'my-auth-token',
                'AUTHENTICATE': sessionStorage.getItem("token"),
            })
        };

        this.http.post(appConstants.apiBaseUrl + 'updateDeviceToken', { device_id: token }, httpOptions)
            .subscribe(data => { });
    }
}