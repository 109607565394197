import { Component, Input, OnInit } from '@angular/core';
import { ProgressSpinnerMode, ThemePalette } from '@angular/material';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
    selector: 'app-mat-spinner-overlay',
    templateUrl: './mat-spinner-overlay.component.html',
    styleUrls: ['./mat-spinner-overlay.component.css']
})

export class MatSpinnerOverlayComponent implements OnInit {
    loading: boolean;

    constructor(private loaderService: SpinnerService) {
        this.loaderService.visibility.subscribe((v) => {
            this.loading = v;
        });
    }

    ngOnInit() { }
}