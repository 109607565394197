import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import appConstants from 'src/app/config/app.constants';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { PaymentConfirmationDialogComponent } from '../../payment-confirmation-dialog/payment-confirmation-dialog.component';

@Component({
  selector: 'app-subscriber-home-new-top-grp',
  templateUrl: './subscriber-home-new-top-grp.component.html',
  styleUrls: ['./subscriber-home-new-top-grp.component.css']
})

export class SubscriberHomeNewTopGrpComponent implements OnInit {
  constructor(
    private router: Router,
    private dataService: SubscriberDataNewService,
    private snackBarSvc: SnackbarService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.response != undefined) {
        if (params.response != 'success') {
          this.dialog.open(PaymentConfirmationDialogComponent, {
            width: "350px",
          }).afterClosed().subscribe(res => {
            this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
          });
        } else {
          this.snackBarSvc.openSnackBar('Payment successful!');
          this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
        }
      }
    });
  }

  myHealthReport() {
    this.router.navigate(['/reports/dashboard']);
  }

  myMedicalSumm() {
    var user_id;
    this.dataService.getMyMedicalSummary().subscribe((res) => {
      user_id = res.data.user_id;
      this.router.navigate(['/subscriber/medical-summary-new/', user_id]);
    }, (err) => {
      var message = (`You dont have a medical summary yet, ${err}`);
      this.snackBarSvc.openSnackBar(message);
    })
  }

  myHealthData() {
    this.router.navigate(['/subscriber/health-data']);
  }

  toMyHealthReport() {
    this.router.navigate(['/reports/dashboard']);
  }
  toMyMedicalSumm() {
    var user_id;
    this.dataService.getMyMedicalSummary().subscribe((res) => {
      user_id = res.data.user_id;

      this.router.navigate(['/subscriber/medical-summary-new/', user_id]);
    }, (err) => {
      var message = (`You dont have a medical summary yet, ${err}`);
      this.snackBarSvc.openSnackBar(message);
    })
  }
  toMyHealthData() {
    this.router.navigate(['/subscriber/health-data']);
  }

  toMyAppointments() {
    this.router.navigate(['/subscriber/appointment-new']);
  }
}
