import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import appConstants from 'src/app/config/app.constants';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { ConfirmCancelOrderDialogComponent } from './confirm-cancel-order-dialog/confirm-cancel-order-dialog.component';
import { ConfirmOrderDialogComponent } from './confirm-order-dialog/confirm-order-dialog.component';
import { ConfirmUpdateOrderDialogComponent } from './confirm-update-order-dialog/confirm-update-order-dialog.component';

@Component({
    selector: 'app-subscriber-order-new',
    templateUrl: './subscriber-order-new.component.html',
    styleUrls: ['./subscriber-order-new.component.css']
})

export class SubscriberOrderNewComponent implements OnInit {
    public billingValues;
    public billingData;
    public billingDate;
    public billingEndDate;
    subscription_type;
    clinicAddress: string;
    public amount;
    tax;
    total;

    constructor(
        public datepipe: DatePipe,
        public router: Router,
        public dialog: MatDialog,
        private dataService: SubscriberDataNewService,
    ) { }

    ngOnInit() {
        this.dataInit();
    }

    dataInit() {
        this.billingValues = JSON.parse(sessionStorage.getItem('doSubmitSubscription'));
        this.billingData = JSON.parse(sessionStorage.getItem("billingData"));
        this.subscription_type = sessionStorage.getItem('sub_type');
        this.billingDate = new Date();

        var year = new Date(this.billingDate).getFullYear();
        var month = new Date(this.billingDate).getMonth();
        var day = new Date(this.billingDate).getDate();

        this.billingDate = this.datepipe.transform(this.billingDate, 'dd-MM-yyyy');
        this.billingEndDate = new Date(year + 1, month, day);

        this.billingEndDate = this.datepipe.transform(this.billingEndDate, 'dd-MM-yyyy')
        this.clinicAddress = `${this.billingData.doctor_address},${this.billingData.doctor_address2},${this.billingData.doctor_state},${this.billingData.doctor_postcode},${this.billingData.doctor_country}`;

        this.amount = this.billingValues.amount;
        this.tax = parseFloat(this.amount) * appConstants.tax.TAX_RATE;
        this.total = parseFloat(this.amount) + parseFloat(this.tax);
    }

    confirmCancel() {
        const dialogRef = this.dialog.open(ConfirmCancelOrderDialogComponent, {
            width: '300px',
        });
    }

    confirmUpdate() {
        const dialogRef = this.dialog.open(ConfirmUpdateOrderDialogComponent, {
            width: '300px',
        });
    }

    confirmOrder() {
        const dialogRef = this.dialog.open(ConfirmOrderDialogComponent, {
            width: '375px',
            data: {
                id: this.billingValues.order_id,
                total: parseFloat(this.total).toFixed(2),
                email: this.billingData.email,
                fullname: `${this.billingData.firstname} ${this.billingData.lastname}`,
            }
        });
    }
}
