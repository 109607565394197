import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from 'src/app/services/http.service';
import { AuthService } from 'src/app/auth.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { SuccessDialogComponent } from 'src/app/components/common/success-dialog/success-dialog.component';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import 'rxjs/add/operator/filter';
import '@opentok/client';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-video-call',
    templateUrl: './video-call.component.html',
    styleUrls: ['./video-call.component.css']
})

export class VideoCallComponent implements OnInit {
    isDesktop: boolean = false;
    subscriberId: any;
    appointmentId: any;
    duration: any = null;
    durationLabel: string = 'N/A';
    estimatedLoadingTime: number = 5;
    hasTeleexam: boolean = false;

    healthData: any; // Complete health data
    heartRate: string = 'N/A';
    bloodPressure: string = 'N/A';
    respiratoryRate: string = 'N/A';
    oxygenSaturation: string = 'N/A';
    temperature: string = 'N/A';
    newsScore: string = 'N/A';

    noteInput: string = '';
    saveButtonLabel: string = 'Save';
    isProcessing: boolean = false;

    isOxygenChecked: boolean = false;
    isAlertnessChecked: boolean = true;

    medicalNoteId: any;
    medicalNoteType: string = 'DN';

    certificateForm: FormGroup;
    today: Date = new Date();
    approveButtonLabel: string = 'Approve';
    isApproved: boolean = false;

    constructor(
        private httpService: HttpService,
        private router: ActivatedRoute,
        private dialog: MatDialog,
        private auth: AuthService,
        private snackBar: SnackbarService,
        public _fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.certificateForm = this._fb.group({
            patient_name: ['', Validators.required],
            patient_email: ['', [Validators.required, Validators.email]],
            doctor_name: ['', Validators.required],
            doctor_apc_no: ['', Validators.required],
            doctor_clinic_name: ['', Validators.required],
            doctor_clinic_address: ['', Validators.required],
            doctor_clinic_phone: ['', Validators.required],
            certificate_type: ['', Validators.required],
            issue_date: [new Date(), Validators.required],
            leave_duration: ['', Validators.required],
            leave_start_date: [new Date(), Validators.required],
            leave_end_date: [new Date(), Validators.required],
            diagnosis: ['', Validators.required],
            remarks: ['']
        });

        this.isDesktop = window.matchMedia("(min-width: 1200px)").matches;
        setInterval(() => {
            this.isDesktop = window.matchMedia("(min-width: 1200px)").matches;
        }, 1000);

        var sessionId = null;
        var token = null;

        this.router.queryParams.subscribe((params: { session_id: any; appointment_id: any; duration: any; has_teleexam: any; }) => {
            if (params.session_id && params.appointment_id) {
                sessionId = params.session_id;
                this.appointmentId = params.appointment_id;
            }

            // Convert duration to seconds
            if (params.duration) {
                this.duration = (parseInt(params.duration) * 60) + this.estimatedLoadingTime;
            }

            // Check if teleexam is enabled
            if (params.has_teleexam) {
                // Convert params.has_teleexam to boolean
                this.hasTeleexam = params.has_teleexam === 'true';
            }
        }, (error: any) => {
            console.error(error);
        });

        if (sessionId == null) return alert('No session id found.');

        this.getHealthData();
        this.initMedicalCertificateForm();

        this.httpService.commonAuthPost(`${appConstants.apiBaseUrl}appointment-session/token`, { session_id: sessionId }).subscribe((res) => {
            if (!res.status) return alert('Something went wrong. Please try again later.');
            token = res.data;
            initializeSession(this.appointmentId, this.dialog, this.auth.isDoctor(), this.httpService);

            // If duration is set, end call after duration
            // if (this.duration) {
            //     let durationIntervalID = setInterval(() => {
            //         this.duration--;
            //         // Convert duration to HH:MM:SS format
            //         let hours = Math.floor(this.duration / 3600);
            //         let minutes = Math.floor((this.duration - (hours * 3600)) / 60);
            //         let seconds = this.duration - (hours * 3600) - (minutes * 60);

            //         let hoursLabel = hours < 10 ? "0" + hours : hours;
            //         let minutesLabel = minutes < 10 ? "0" + minutes : minutes;
            //         let secondsLabel = seconds < 10 ? "0" + seconds : seconds;

            //         this.durationLabel = `${hoursLabel}:${minutesLabel}:${secondsLabel}`;

            //         // Give alert when 1 minute left
            //         if (this.duration === 60) {
            //             this.dialog.open(SuccessDialogComponent, {
            //                 width: '400px',
            //                 data: {
            //                     title: '1 Minute Left',
            //                     message: 'You have 1 minute left in this session.',
            //                     is_info: true,
            //                 }
            //             });
            //         }

            //         if (this.duration <= 15) {
            //             let durationLabel = document.getElementById("duration-label");
            //             if (durationLabel) {
            //                 durationLabel.classList.toggle("text-danger");
            //                 durationLabel.classList.toggle("text-white");
            //             }
            //         }

            //         if (this.duration === 0) {
            //             clearInterval(durationIntervalID);
            //             // Click end call button
            //             let endCallButton = document.getElementById("endCallAuto");
            //             if (endCallButton) endCallButton.click();
            //         }
            //     }, 1000); // 1 second interval
            // }
        });

        function initializeSession(appointmentId, dialog, isDoctor = false, HttpService = null) {
            console.log('Initializing session');
            var session = OT.initSession(appConstants.apiTokBoxKey, sessionId);
            if (session.connection) session.disconnect(); // Disconnect any existing connection

            session.on('streamCreated', function (event) {
                console.log('Stream created');
                session.subscribe(event.stream, 'subscriber', {
                    insertMode: 'append',
                    width: '100%',
                    height: '100%',
                    style: { buttonDisplayMode: 'off' }
                }, handleError);
            });

            var publisher = OT.initPublisher('publisher', {
                insertMode: 'append',
                width: '100%',
                height: '100%',
                style: { buttonDisplayMode: 'off' }
            }, handleError);

            session.connect(token, function (error) {
                console.log('Connecting');
                if (error) {
                    console.log('Error connecting');
                    handleError(error);
                } else {
                    console.log('Connected');
                    session.publish(publisher, handleError);
                    document.getElementById("actionButton").style.display = "block";
                }
            });

            document.getElementById("toggleMic").addEventListener("click", () => toggleMic(publisher));
            document.getElementById("toggleCamera").addEventListener("click", () => toggleCamera(publisher));
            document.getElementById("endCall").addEventListener("click", () => endCall(appointmentId, session, dialog, isDoctor, HttpService));

            // KIV: Temporary solution for ending call automatically after duration
            document.getElementById("endCallAuto").addEventListener("click", () => endCallAuto(appointmentId, session, HttpService));
        }

        function toggleMic(publisher) {
            publisher.publishAudio(!publisher.stream.hasAudio);
            var classess = publisher.stream.hasAudio ? "fas fa-lg fa-microphone-slash" : "fas fa-lg fa-microphone";
            document.getElementById("micIcon").className = classess;
        }

        function toggleCamera(publisher) {
            publisher.publishVideo(!publisher.stream.hasVideo);
            var classess = publisher.stream.hasVideo ? "fas fa-lg fa-video-slash" : "fas fa-lg fa-video";
            document.getElementById("camIcon").className = classess;
        }

        function endCall(appointmentId, session, dialog, isDoctor = false, HttpService = null) {
            let dialogRef = dialog.open(ConfirmDialogComponent, {
                width: '400px',
                data: {
                    title: 'Confirm End Session',
                    message: 'Are you sure you want to end the session?<br>You will not be able to reenter the session again.'
                }
            });

            dialogRef.afterClosed().subscribe((res) => {
                if (res) {
                    let is_complete = completeAppointment(appointmentId, HttpService);

                    if (is_complete) {
                        session.disconnect();
                        document.getElementById("videoCallWrapper").style.display = "none";
                        document.getElementById("endCallWrapper").style.display = "flex";
                    } else {
                        dialog.open(ErrorDialogComponent, {
                            width: '400px',
                        });
                    }
                }
            })
        }

        // KIV: Temporary solution for ending call automatically after duration
        function endCallAuto(appointmentId, session, HttpService = null) {
            completeAppointment(appointmentId, HttpService);
            session.disconnect();
            document.getElementById("videoCallWrapper").style.display = "none";
            document.getElementById("endCallWrapper").style.display = "flex";
        }

        function handleError(error) {
            if (error) {
                alert(error.message);
            }
        }

        function completeAppointment(appointmentId, HttpService = null) {
            let dds = new DoctorDataService(HttpService);

            dds.completeAppointment({ id: appointmentId }).subscribe(res => {
                return res.status;
            });

            return true;
        }
    }

    async initMedicalCertificateForm() {
        const res = await this.httpService.commonAuthPost(`${appConstants.apiBaseUrl}getAppointment`, { id: this.appointmentId }).toPromise();

        let temp_patient_name = res.status ? res.data.subscriber_name : '';
        let temp_patient_email = res.status ? res.data.subscriber_email : '';
        let temp_doctor_name = res.status ? res.data.doctor_name : '';
        let temp_doctor_apc_no = res.status ? res.data.doctor_apc_no : '';
        let temp_clinic_address = res.status ? res.data.clinic_address : '';
        let temp_clinic_name = res.status ? res.data.clinic_name : '';
        let temp_clinic_phone = res.status ? res.data.clinic_phone_no : '';

        this.certificateForm = this._fb.group({
            patient_name: [temp_patient_name, Validators.required],
            patient_email: [temp_patient_email, [Validators.required, Validators.email]],
            doctor_name: [temp_doctor_name, Validators.required],
            doctor_apc_no: [temp_doctor_apc_no, Validators.required],
            doctor_clinic_name: [temp_clinic_name, Validators.required],
            doctor_clinic_address: [temp_clinic_address, Validators.required],
            doctor_clinic_phone: [temp_clinic_phone, Validators.required],
            certificate_type: ['', Validators.required],
            issue_date: [new Date(), Validators.required],
            leave_duration: ['', Validators.required],
            leave_start_date: [new Date(), Validators.required],
            leave_end_date: [new Date(), Validators.required],
            diagnosis: ['', Validators.required],
            remarks: ['']
        });
    }

    async getHealthData() {
        const res = await this.httpService.commonAuthPost(`${appConstants.apiBaseUrl}getAppointment`, { id: this.appointmentId }).toPromise();
        let dataService = null;
        this.subscriberId = res.data.sub_id;

        if (this.auth.isDoctor()) {
            dataService = new DoctorDataService(this.httpService).getLatestDeviceData({ sub_id: this.subscriberId });
        } else if (this.auth.isSubscriber()) {
            dataService = new SubscriberDataNewService(this.httpService, null, null).latestDeviceData();
        }

        if (dataService) {
            const res = await dataService.toPromise();
            if (res.data) {
                this.healthData = res.data;

                this.heartRate = res.data.heart_rate;
                this.bloodPressure = res.data.bp;
                this.respiratoryRate = res.data.respiratory_rate;
                this.oxygenSaturation = res.data.oxygen_saturation;
                this.temperature = res.data.temperature;

                this.newsScore = this.calculateNEWsScore().toString();
            }
        }
    }

    // KIV: Notes format
    saveNotes() {
        const noteInput = document.getElementById('note-input') as HTMLInputElement | null;
        this.noteInput = noteInput.value;
        this.upsertMedicalNotes();
    }

    submitMedicalCertificate() {
        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: {
                title: 'Confirm Approve Medical Certificate',
                message: 'Are you sure you want to approve the medical certificate? Approved medical certificate cannot be edited or deleted.<br><br> Please make sure all the details are correct.'
            }
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                if (this.certificateForm.valid) {
                    const data = {
                        appointment_id: this.appointmentId,
                        patient_name: this.certificateForm.value.patient_name,
                        patient_email: this.certificateForm.value.patient_email,
                        doctor_name: this.certificateForm.value.doctor_name,
                        doctor_apc_no: this.certificateForm.value.doctor_apc_no,
                        doctor_clinic_name: this.certificateForm.value.doctor_clinic_name,
                        doctor_clinic_address: this.certificateForm.value.doctor_clinic_address,
                        doctor_clinic_phone: this.certificateForm.value.doctor_clinic_phone,
                        certificate_type: this.certificateForm.value.certificate_type,
                        issue_date: new Date(this.certificateForm.value.issue_date).toLocaleDateString('en-GB'),
                        leave_duration: this.certificateForm.value.leave_duration,
                        leave_start_date: new Date(this.certificateForm.value.leave_start_date).toLocaleDateString('en-GB'),
                        leave_end_date: new Date(this.certificateForm.value.leave_end_date).toLocaleDateString('en-GB'),
                        diagnosis: this.certificateForm.value.diagnosis,
                        remarks: this.certificateForm.value.remarks,
                        status: 2,
                    };

                    this.httpService.commonAuthPost(`${appConstants.apiBaseUrl}medical-certificate`, data).subscribe((res) => {
                        if (res.status) {
                            this.isApproved = true;
                            this.approveButtonLabel = 'Approved';

                            this.dialog.open(SuccessDialogComponent, {
                                width: '400px',
                                data: {
                                    title: 'Medical Certificate Approved',
                                    message: 'Medical certificate has been approved and sent to the patient.',
                                }
                            });

                            for (let key in this.certificateForm.controls) {
                                this.certificateForm.controls[key].disable();
                            }
                        } else {
                            let errors = res.data;
                            let errorMessages = '';

                            // First check if errors is an array or string
                            if (typeof errors === 'string') {
                                errorMessages = errors;
                            } else if (typeof errors === 'object') {
                                for (let key in errors) {
                                    errorMessages += `${errors[key]}<br>`;
                                }
                            }

                            this.dialog.open(ErrorDialogComponent, {
                                width: '400px',
                                data: {
                                    title: 'Error Approving Medical Certificate',
                                    message: errorMessages,
                                }
                            });
                        }
                    });
                } else {
                    this.dialog.open(ErrorDialogComponent, {
                        width: '400px',
                        data: {
                            title: 'Error Approving Medical Certificate',
                            message: 'Please make sure all the details are correct.',
                        }
                    });

                    // Show error messages
                    for (let key in this.certificateForm.controls) {
                        this.certificateForm.controls[key].markAsTouched();
                    }
                }
            }
        });
    }

    upsertMedicalNotes() {
        this.isProcessing = true;
        const content = this.noteInput;

        const data = {
            id: this.medicalNoteId,
            sub_id: this.subscriberId,
            appointment_id: this.appointmentId,
            type: this.medicalNoteType,
            content: content.trim(),
        };

        if (!this.medicalNoteId) {
            this.httpService.commonAuthPost(`${appConstants.apiBaseUrl}notes/create`, data).subscribe((res) => {
                this.medicalNoteId = res.data;
                this.saveButtonLabel = 'Update';

                this.isProcessing = false;
                this.snackBar.openSnackBar('Note saved successfully');
            });
        } else {
            this.httpService.commonAuthPost(`${appConstants.apiBaseUrl}notes/update`, data).subscribe((res) => {
                this.isProcessing = false;
                this.snackBar.openSnackBar('Note updated successfully');
            });
        }
    }

    calculateNEWsScore() {
        if (
            this.healthData.respiratory_rate == "N/A" ||
            this.healthData.oxygen_saturation == "N/A" ||
            this.healthData.temperature == "N/A" ||
            this.healthData.sys == "N/A" ||
            this.healthData.heart_rate == "N/A"
        ) {
            return "Insufficient data";
        }

        let total = 0;
        let status = "";

        let score = {
            oxy_supp: this.isOxygenChecked ? 2 : 0,
            avpu_score: this.isAlertnessChecked ? 0 : 3,
        };

        if (this.healthData.respiratory_rate <= 8 || this.healthData.respiratory_rate >= 25) {
            score['res_rate'] = 3;
        } else if (this.healthData.respiratory_rate >= 21 && this.healthData.respiratory_rate <= 24) {
            score['res_rate'] = 2;
        } else if (this.healthData.respiratory_rate >= 9 && this.healthData.respiratory_rate <= 11) {
            score['res_rate'] = 1;
        } else {
            score['res_rate'] = 0;
        }

        if (this.healthData.oxygen_saturation <= 91) {
            score['oxy_sat'] = 3;
        } else if (this.healthData.oxygen_saturation >= 92 && this.healthData.oxygen_saturation <= 93) {
            score['oxy_sat'] = 2;
        } else if (this.healthData.oxygen_saturation >= 94 && this.healthData.oxygen_saturation <= 95) {
            score['oxy_sat'] = 1;
        } else {
            score['oxy_sat'] = 0;
        }

        if (this.healthData.temperature <= 35) {
            score['temperature'] = 3;
        } else if (this.healthData.temperature > 39.0) {
            score['temperature'] = 2;
        } else if (
            (this.healthData.temperature >= 35.1 && this.healthData.temperature <= 36.0) ||
            (this.healthData.temperature >= 38.1 && this.healthData.temperature <= 39.0)
        ) {
            score['temperature'] = 1;
        } else {
            score['temperature'] = 0;
        }

        if (this.healthData.sys <= 90 || this.healthData.sys >= 220) {
            score['sys_press'] = 3;
        } else if (this.healthData.sys >= 91 && this.healthData.sys <= 100) {
            score['sys_press'] = 2;
        } else if (this.healthData.sys >= 101 && this.healthData.sys <= 110) {
            score['sys_press'] = 1;
        } else {
            score['sys_press'] = 0;
        }

        if (this.healthData.heart_rate <= 40 || this.healthData.heart_rate >= 131) {
            score['heart_rate'] = 3;
        } else if (this.healthData.heart_rate >= 111 && this.healthData.heart_rate <= 130) {
            score['heart_rate'] = 2;
        } else if (
            (this.healthData.heart_rate >= 41 && this.healthData.heart_rate <= 50) ||
            (this.healthData.heart_rate >= 91 && this.healthData.heart_rate <= 110)
        ) {
            score['heart_rate'] = 1;
        } else {
            score['heart_rate'] = 0;
        }

        for (let key in score) total += score[key];
        status = total <= 4 ? 'Low' : ((total > 4 && total <= 6) ? 'Medium' : (total > 6 ? 'High' : ''));

        return total;
    }

    toggleSupplementalOxygen() {
        this.isOxygenChecked = !this.isOxygenChecked;
        this.newsScore = this.calculateNEWsScore().toString();
    }

    toggleAlertness() {
        this.isAlertnessChecked = !this.isAlertnessChecked;
        this.newsScore = this.calculateNEWsScore().toString();
    }

    showNotesSection() {
        this.closeMedicalDocuments();
        const notesSection = document.getElementById('notesSection') as HTMLElement | null;
        notesSection.style.display = notesSection.style.display == 'none' ? 'block' : 'none';
    }

    showMedicalCertificateSection() {
        this.closeMedicalDocuments();
        const medicalCertificateSection = document.getElementById('medicalCertificateSection') as HTMLElement | null;
        medicalCertificateSection.style.display = medicalCertificateSection.style.display == 'none' ? 'block' : 'none';
    }

    closeMedicalDocuments() {
        const medicalDocuments = document.getElementsByClassName('medical-documents-js') as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < medicalDocuments.length; i++) {
            medicalDocuments[i].style.display = 'none';
        }
    }
}
