import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import appConstants from './config/app.constants';

@Injectable({
    providedIn: "root"
})

export class AuthService {
    constructor(private myRoute: Router) { }

    setLoginDetails(data: Object) {
        sessionStorage.setItem('userdata', JSON.stringify(data));
    }

    setLoginToken(token: Object) {
        sessionStorage.setItem('token', JSON.stringify(token));
    }

    getLoginDetails() {
        return sessionStorage.getItem("userdata");
    }

    isLoggednIn() {
        return this.getLoginDetails() !== null;
    }

    getUserId() {
        const userData = this.getLoginDetails();
        if (userData && JSON.parse(userData).user_id) {
            return JSON.parse(userData).user_id;
        }
        return null;
    }

    getDoctorId() {
        const userData = this.getLoginDetails();
        if (userData && JSON.parse(userData).doctor_id) {
            return JSON.parse(userData).doctor_id;
        }
        return null;
    }

    username() {
        return JSON.parse(sessionStorage.getItem("userdata")).firstname;
    }

    isSubscriber() {
        return JSON.parse(sessionStorage.getItem("userdata")).category === appConstants.userType.SUBSCRIBER;
    }

    isDoctor() {
        return JSON.parse(sessionStorage.getItem("userdata")).category === appConstants.userType.DOCTOR;
    }

    isCorporate() {
        return JSON.parse(sessionStorage.getItem("userdata")).category === appConstants.userType.CORP;
    }

    getUserType() {
        return JSON.parse(sessionStorage.getItem("userdata")).category_name;
    }

    getMRN() {
        return JSON.parse(sessionStorage.getItem("userdata")).mrn;
    }

    logout() {
        sessionStorage.removeItem("userdata");
        sessionStorage.clear();
        this.myRoute.navigate([appConstants.routingList.LOGIN_COMPONENT]);
    }
}
