import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { DataService } from 'src/app/services/data.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { PurchaseCreditDialogComponent } from 'src/app/components/subscriber/purchase-credit-dialog/purchase-credit-dialog.component';
import { SubsTeleconsultationDialogComponent } from 'src/app/components/subscriber/teleconsultation-dialog/teleconsultation-dialog.component';
import { DocTeleconsultationDialogComponent } from 'src/app/components/doctor/teleconsultation-dialog/teleconsultation-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/common/error-dialog/error-dialog.component';
import { MessagingService } from 'src/app/messaging.service';
import { WaitingDialogComponent } from 'src/app/components/common/waiting-dialog/waiting-dialog.component';

@Component({
	selector: 'app-home-new',
	templateUrl: './home-new.component.html',
	styleUrls: ['./home-new.component.css']
})

export class HomeNewComponent implements OnInit {
	username: string
	creditBalance: number = 0;
	callNotification: number = 0;
	profileFlag: Boolean = false;
	drInfoFlag: Boolean = false;
	subscriptionFlag: Boolean = false;
    waitDialog: MatDialogRef<WaitingDialogComponent>;
	audio = new Audio();

	// Polling for call notification on doctor side
    isPaused: boolean = false;
    pollIntervalId: any;

	// Push notification
    message: any;

	constructor(
		private router: Router,
		public auth: AuthService,
		private dialog: MatDialog,
		private snackBarSvc: SnackbarService,
		private dataService: DataService,
		private subsDataService: SubscriberDataNewService,
		private docDataService: DoctorDataService,
        private messagingService: MessagingService
	) {
		// Clear polling interval when navigating away from home
		this.router.events.subscribe((val) => {
			if (this.router.url != '/home') clearInterval(this.pollIntervalId);
		});
	}

	ngOnInit() {
		this.dataService.currentUsername.subscribe(res => this.username = res);
		this.dataService.updateUsername(JSON.parse(this.auth.getLoginDetails()).firstname);

		if (this.auth.isSubscriber()) {
			this.subsDataService.getAppointmentCreditBalance().subscribe((res) => {
				this.creditBalance = res.data;
			});
		}

		if (this.auth.isDoctor()) this.getCallNotification();

		this.getActiveTeleSession();
		this.audio.src = "../../../assets/audios/teleconsultation-calling-request.mp3";
	}

	ngAfterViewInit() {
		// Wait for 5 seconds before asking for permission because of the delay in loading firebase and service worker
        setTimeout(() => {
			// Check if browser supports push notification
			if (this.messagingService.isSupported()) {
				this.messagingService.getPermission();
				this.messagingService.receiveMessage();
				this.message = this.messagingService.currentMessage;
			}
        }, 5000);
    }

	canDeactivate() {
		return confirm("Do you really want to leave?");
	}

	purchaseCredit() {
		this.dialog.open(PurchaseCreditDialogComponent, {
			width: '400px',
		});
	}

	openTeleconsultDialog() {
		if (this.auth.isSubscriber()) {
			this.subsDataService.getAppointmentCreditBalance().subscribe((res) => {
				let data = {};
				let tempComponent = SubsTeleconsultationDialogComponent as any;
				let width = '600px';

				if (res.data <= 0) {
					tempComponent = ErrorDialogComponent;
					data = {
						title: 'Insufficient Credits',
						message: 'You have no credits left to add an appointment.',
					}
					width = '400px';
				}

				this.dialog.open(tempComponent, {
					width: width,
					data: data,
				});
			});
		}

		if (this.auth.isDoctor()) {
			let dialogRef = this.dialog.open(DocTeleconsultationDialogComponent, {
				width: '600px',
			});

			dialogRef.afterOpened().subscribe((res) => {
				clearInterval(this.pollIntervalId);
			});

			dialogRef.afterClosed().subscribe((res) => {
				this.getCallNotification();
			});
		}
	}

	getCallNotification() {
        this.pollIntervalId = setInterval(() => {
            if (!this.isPaused) this.getWaitingSubscriber();
        }, 3000);
    }

	getWaitingSubscriber() {
        this.isPaused = true;
		this.docDataService.getWaitingSubscriber().subscribe((res) => {
			this.callNotification = res.data.length;
			if (this.callNotification > 0 && this.audio.paused) {
				this.audio.play();
				this.audio.loop = true;
			} else {
				this.audio.pause();
				this.audio.currentTime = 0;
			}
			this.isPaused = false;
        });
	}

	getActiveTeleSession() {
		this.dataService.getActiveTeleSession().subscribe((res) => {
			const data = res.data;
			if (res.status) this.redirectToVideoCall(data.session_id, data.appointment_id, data.duration, data.has_teleexam);
		});
	}

	redirectToVideoCall(session_id, appointment_id, duration, has_teleexam) {
        let waitingTime = 15;

		this.waitDialog = this.dialog.open(WaitingDialogComponent, {
            width: '400px',
            disableClose: true,
            data: {
                title: `Redirecting to Video Call (${waitingTime} seconds)`,
                message: 'You have an active teleconsultation session. You will be redirected to the video call page.',
                cancel_text: 'OK'
            }
        });

		let waitIntervalId = setInterval(() => {
            waitingTime--;
            this.waitDialog.componentInstance.title = `Redirecting to Video Call (${waitingTime} seconds)`;
            if (waitingTime === 0) this.waitDialog.close();
        }, 1000);

        this.waitDialog.afterClosed().subscribe((ok) => {
			clearInterval(waitIntervalId);
			this.router.navigate(['/video-call'], {
				queryParams: {
					session_id: session_id,
					appointment_id: appointment_id,
					duration: duration,
					has_teleexam: has_teleexam,
				}
			});
        });
	}
}
