import { Component, OnInit, ViewChild } from '@angular/core';
import { DoctorDataService } from '../services/doctor/doctor-data.service';
import { MatDialog, MatDialogModule, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DoctorNoteInterface } from '../components/interface/doctor/doctor-note.interface';
import { IsoDateService } from 'src/app/util/date/iso-date.service';
import { MatButtonModule } from '@angular/material/button';
import { DoctorNoteViewDialogComponent } from './doctor-note-view/doctor-note-view.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'app-doctor-note',
    templateUrl: './doctor-note.component.html',
    styleUrls: ['./doctor-note.component.css'],
})

export class DoctorNoteComponent implements OnInit {
    dataList: any;
    dataSource = new MatTableDataSource<DoctorNoteInterface>();

    displayedColumns: string[] = ['sub_id', 'subject', 'subscriber_name', 'notes', 'date', 'action'];
    pageSizeOptions: number[] = [5, 10, 20];
    pageSize = 100;

    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;


    constructor(
        private doctorDataSvc: DoctorDataService,
        private dialog: MatDialog,
        private isoDate: IsoDateService,
        private breakpointObserver: BreakpointObserver,
    ) { }

    ngOnInit() {
        this.getNotes();
    }

    getNotes() {
        this.doctorDataSvc.getNotes().subscribe((res) => {
            this.dataList = res.data;
            this.dataSource = new MatTableDataSource<DoctorNoteInterface>(this.dataList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
    }

    openCreate() {
        let width = '95%';
        if (this.breakpointObserver.isMatched(Breakpoints.Large)) width = '60%';

        const dialogRef = this.dialog.open(DoctorNoteViewDialogComponent, {
            width: width,
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getNotes();
        });
    }

    openNote(row: any) {
        let width = '95%';
        if (this.breakpointObserver.isMatched(Breakpoints.Large)) width = '60%';

        const dialogRef = this.dialog.open(DoctorNoteViewDialogComponent, {
            width: width,
            data: row,
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getNotes();
        });
    }
}