import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../services/http.service';
import appConstants from '../config/app.constants';
import * as moment from 'moment';

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.css']
})

export class InvoiceComponent implements OnInit {
    public doctorApp: FormGroup;

    listViewUi: boolean = false;
    dataSubscriber: string = '';
    dataList: any[] = [];
    data: any = {};

    invoiceDetails: boolean = false;
    doctorId: string = '';
    patientList: any[] = [];
    viewInvoice: any = {};

    constructor(
        private _fb: FormBuilder,
        private toastrService: ToastrService,
        private httpService: HttpService
    ) { }

    async ngOnInit() {
        this.doctorApp = this._fb.group({
            id: [''],
            subscriber: ['', [Validators.required]],
            notes: ['', [Validators.required]],
            content: ['']
        });
        // await this.getPatientList();
        this.getInvoices();
    }

    // async getPatientList() {
    //   this.patientList = (await this.httpService.commonAuthPromisePost(appConstants.apiBaseUrl + 'getSubscriberList', {})).data;
    // }

    formatDate(dt, format: string) {
        return moment.default(dt).format(format);
    }

    getPatientName(subscription_id: string) {
        const patient = this.patientList.find(p => p.id === subscription_id);
        return (patient ? (patient.firstname + patient.lastname) : '');
    }

    getInvoices() {
        this.dataList = [];
        this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'invoice/list', {}).subscribe(res => {
            for (const data of res.data) {
                this.dataList.push({
                    id: data.id,
                    subscriptionId: data.subscription_id,
                    description: data.description,
                    tax: data.tax,
                    amount: data.amount,
                    status: data.status,
                    createdAt: data.created_at,
                })
            }
        });
    }

    getInvoice(id: number) {
        this.data = {};
        this.httpService.commonAuthGet(appConstants.apiBaseUrl + 'invoice/view/9', {}).subscribe(res => {
            this.data = res.data;
            this.data['orderNo'] = `INV-${this.formatDate(this.data.createdAt, 'YYYY')}-${this.data.id.padStart(6, '0')}`;
            this.data['taxAmount'] = this.data.tax / 100 * this.data.amount;
        });
    }

    openOnRowModal() {
        this.invoiceDetails = true;
    }

    closeOnRowModal() {
        this.viewInvoice = {};
        this.invoiceDetails = false;
    }

    onRowSelect(event: any) {
        this.openOnRowModal();
        this.getInvoice(event.id);
        this.viewInvoice = event;
    }
}
