import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { BloodPressureDataInterface } from 'src/app/components/interface/subscriber/blood-pressure.interface';
import { BodyMeasurementDataInterface } from 'src/app/components/interface/subscriber/body-measurement.interface';
import appConstants from 'src/app/config/app.constants';
import { DoctorDataService } from '../doctor/doctor-data.service';
import { HttpService } from '../http.service';

@Injectable({
    providedIn: 'root'
})

export class SubscriberDataNewService {
    public healthData: BodyMeasurementDataInterface[] = [];
    public bpData: BloodPressureDataInterface[];
    public selectedCategory: string;
    private httpService: HttpService;

    constructor(
        httpService: HttpService,
        public datePipe: DatePipe,
        private auth: AuthService,
    ) { this.httpService = httpService; }

    getBodyMeasurementData(startDate, endDate, sub_id?) {
        this.selectedCategory = 'Body Measurement';
        let params = {
            date_from: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
            date_to: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
            category: this.selectedCategory,
        };

        if (this.auth.isDoctor()) params['sub_id'] = sub_id;
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getDeviceData', params);
    }

    getBPData(startDate, endDate, sub_id?) {
        this.selectedCategory = 'Blood Pressure';
        let params = {
            date_from: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
            date_to: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
            category: this.selectedCategory,
        };

        if (this.auth.isDoctor()) params['sub_id'] = sub_id;
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getDeviceData', params);
    }

    getHeartRateData(startDate, endDate, sub_id?) {
        this.selectedCategory = 'Hourly Heart Rate';
        let params = {
            date_from: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
            date_to: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
            category: this.selectedCategory,
        };

        if (this.auth.isDoctor()) params['sub_id'] = sub_id;
        return this.httpService.commonAuthPost(appConstants.apiBaseUrlCI2 + 'getDeviceData', params);
    }

    getSleepPatternData(startDate, endDate, sub_id?) {
        this.selectedCategory = "Sleep Pattern";
        let params = {
            date_from: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
            date_to: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
            category: this.selectedCategory,
        };

        if (this.auth.isDoctor()) params['sub_id'] = sub_id;
        return this.httpService.commonAuthPost(appConstants.apiBaseUrlCI2 + 'getDeviceData', params);
    }

    getActivityHealthData(startDate, endDate, sub_id?) {
        this.selectedCategory = "Activity";
        let params = {
            date_from: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
            date_to: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
            category: this.selectedCategory,
        };

        if (this.auth.isDoctor()) params['sub_id'] = sub_id;
        return this.httpService.commonAuthPost(appConstants.apiBaseUrlCI2 + 'getDeviceData', params);
    }

    getAppointmentData() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getAppointmentBySubscriber', {});
    }

    addAppointment(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'addAppointment', params);
    }

    getBillingInfo() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyDoctorInfo', {});
    }

    getPackageList(params = {}) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getPackageList', params);
    }

    previewOrder(doc_id, body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'doSubmitSubscription', { dr_id: doc_id, package: body.subscription_type });
    }

    purchaseCredit(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'doPurchaseCredit', params);
    }

    generateSignature(params) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'payment/generate-signature', params);
    }

    getMyAccountInfo() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyAccount', {});
    }

    confirmOrder(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'updatePaymentInfo', body);
    }

    getMyInitialTarget(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyInitialTarget', body);
    }

    getInPersonCreateCheck() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'inPersonCreateCheck', {});
    }

    getMyMedicalSummary() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyMedicalSummary', {});
    }

    getSubStatus() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyCurrentSubscription', {});
    }

    latestDeviceData() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getLatestDeviceData', {});
    }

    deleteAppointment(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'deleteAppointment', body);
    }

    cancelAppointment(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'cancelAppointment', body);
    }

    getAppointmentCreditBalance() {
        return this.httpService.commonAuthGet(appConstants.apiBaseUrl + 'appointment-credit/balance');
    }

    getAvailableDoctor(body = {}) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getAvailableDoctor', body);
    }

    getAvailableTeleconsultationType() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getAvailableTeleconsultationType', {});
    }

    createTeleconsultation(body) {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'createTeleconsult', body);
    }

    cancelTeleconsultation() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'cancelTeleconsult', {});
    }

    getTeleconsultationStatus() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getTeleStatus', {});
    }

    getDeviceData(params: any, useCI2 = false) {
        let apiURL = useCI2 ? appConstants.apiBaseUrlCI2 : appConstants.apiBaseUrl;
        return this.httpService.commonAuthPost(apiURL + 'getDeviceData', params);
    }

    getInvoiceList() {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'invoice/list', {});
    }

    getInvoiceDetail(invoice_id) {
        return this.httpService.commonAuthGet(appConstants.apiBaseUrl + `invoice/view/${invoice_id}`);
    }
}
