import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DoctorAppointmentComponent } from 'src/app/old-components/doctor-appointment/doctor-appointment.component';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { ErrorDialogComponent } from 'src/app/components/common/error-dialog/error-dialog.component';

@Component({
    selector: 'app-doctor-appointments-view',
    templateUrl: './doctor-appointments-view.component.html',
    styleUrls: ['./doctor-appointments-view.component.css']
})

export class DoctorAppointmentsViewComponent implements OnInit {
    public readonly: boolean = true;
    public is_passed: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<DoctorAppointmentComponent>,
        public dialog: MatDialog,
        private doctorDataSvc: DoctorDataService,
        public _sb: SnackbarService,
    ) { }

    ngOnInit() {
        this.is_passed = new Date(this.data.date) < new Date();
    }

    reschedule() {
        this.doctorDataSvc.rejectAppointment({ id: this.data.id }).subscribe(res => {
            this.updatedStatus('Rejected', res);
        });
    }

    confirm(): void {
        this.doctorDataSvc.confirmAppointment({ id: this.data.id }).subscribe(res => {
            this.updatedStatus('Confirmed', res);
        });
    }

    cancel() {
        this.doctorDataSvc.cancelAppointment({ id: this.data.id }).subscribe(res => {
            this.updatedStatus('Cancelled', res);
        });
    }

    complete() {
        this.doctorDataSvc.completeAppointment({ id: this.data.id }).subscribe(res => {
            this.updatedStatus('Completed', res);
        });
    }

    private updatedStatus(status, res) {
        this.dialogRef.close();

        if (res.status == true) {
            this._sb.openSnackBar(`Appointment ${status}`);
        } else {
            this.dialog.open(ErrorDialogComponent, {
                width: '350px',
                data: {
                    title: 'Something went wrong',
                    message: res.data,
                },
            });
        }
    }

    onNoClick() {
        this.dialogRef.close();
    }
}
