import { environment } from '../../environments/environment';
const appConstants = {
    routingList: {
        LOGIN_COMPONENT: '/login-new',
        SIGNUP_COMPONENT: '/sign-up-new',
        DOCTOR_COMPONENT: '/doctor',
        SUBSCRIBER_COMPONENT: '/subscriber',
        HOME_COMPONENT: '/home',
        SUBSCRIBER_MEDICAL_SUMMARY_COMPONENT: '/subscriber/medical-summary',
        DOCTOR_MEDICAL_SUMMARY_COMPONENT: '/doctor-medical-summary'
    },
    /*userType: {
        SUBSCRIBER: 'Subscriber' ,
        DOCTOR : 'Doctor',
        CORP : 'WeCheckup'
    },*/
    userType: {
        SUBSCRIBER: '2',
        DOCTOR: '1',
        CORP: '9'
    },
    apiBaseUrl: environment.apiUrl,
    apiBaseUrlCI2: environment.apiUrlCI2,
    apiTokBoxKey: environment.apiTokBoxKey,
    claimStatusList: {
        NEW: "New",
        SUBMITED: "Submited",
        DONE: "Done"
    },
    tax: {
        TAX_RATE: 0.1,
    },
    payment: {
        MERCHANT_KEY: "FxHUAi777j",
        MERCHANT_CODE: "M28617",
    }
};

export default appConstants;
