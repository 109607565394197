import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { IsoDateService } from 'src/app/util/date/iso-date.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { ClaimsTableInterface } from '../../interface/doctor/doctor-claims-table.interface';
import { SubscriberList } from '../../interface/doctor/doctor-sub-list.interface';
import { ConfirmDialogComponent } from 'src/app/components/common/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-doctor-claims',
    templateUrl: './doctor-claims.component.html',
    styleUrls: ['./doctor-claims.component.css']
})

export class DoctorClaimsComponent implements OnInit {
    dateRangeForm: FormGroup;
    subList: SubscriberList;
    type: string;
    dataSource = new MatTableDataSource<ClaimsTableInterface>();
    displayedColumns: string[] = ['select', 'name', 'created', 'package', 'amount'];
    pageSizeOptions: number[] = [5, 10, 20];
    pageSize = 100;
    selection = new SelectionModel<ClaimsTableInterface>(true, []);
    total: number = 0;
    confirmationDialog: MatDialogRef<ConfirmDialogComponent>;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(
        public _fb: FormBuilder,
        public datePipe: DatePipe,
        private doctorDataSvc: DoctorDataService,
        public _sbSvc: SnackbarService,
        public dialog: MatDialog,
        private isoDate: IsoDateService,
    ) { }

    ngOnInit() {
        this.formInit();
        this.getSubList();
        this.type = '1';
    }

    formInit() {
        this.dateRangeForm = this._fb.group({
            start_date: new FormControl('', Validators.required),
            end_date: new FormControl('', Validators.required),
        });
    }

    get f() { return this.dateRangeForm.controls };

    option(event) {
        this.type = event.value;
        if (this.type == '2') {
            this.dateRangeForm.addControl('sub_id', new FormControl('', Validators.required));
            this.reset();
        } else if (this.type == '1') {
            this.dateRangeForm.removeControl('sub_id');
            this.reset();
        }
    }

    getSubList() {
        this.doctorDataSvc.getSubList().subscribe((res) => {
            this.subList = res.data;
        })
    }

    onSubmit() {
        this.total = 0;
        let formattedStartDate = this.datePipe.transform(this.dateRangeForm.value.start_date, 'yyyy-MM-dd');
        let formattedEndDate = this.datePipe.transform(this.dateRangeForm.value.end_date, 'yyyy-MM-dd');

        let params = {
            date_from: formattedStartDate,
            date_to: formattedEndDate,
            sub_id: '',
        }

        if (this.type == '2') {
            params.sub_id = this.dateRangeForm.value.sub_id;
        }

        this.doctorDataSvc.getTransaction(params).subscribe((res) => {
            this.dataSource = new MatTableDataSource<ClaimsTableInterface>(res.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: ClaimsTableInterface): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
    }

    onTableSubmit() {
        var checkedIDs: number[] = []
        this.selection.selected.forEach(element => {
            checkedIDs.push(element.transaction_id);
        });

        this.confirmationDialog = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            disableClose: true,
            data: {
                title: 'Confirm Claims',
                message: 'I understand that I need to treat my patients before proceeding to make any claims.',
                cancel_text: 'Cancel',
                confirm_text: 'Proceed',
            }
        });

        this.confirmationDialog.afterClosed().subscribe((proceed) => {
            if (proceed) {
                this.doctorDataSvc.doClaimTransaction({ transactions_id: checkedIDs }).subscribe((res) => {
                    this._sbSvc.openSnackBar('Your claim has been submitted, Please wait to hear back from us.');
                }, (err) => {
                    this._sbSvc.openSnackBar(`An error has occured ${err}`);
                });
            }

            this.onSubmit();
            this.selection.clear();
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
    }

    getTotalCost() {
        this.total = this.selection.selected.map(i => i.amount).reduce((total, value) => total + parseFloat(value.toString()), 0);
        return this.total;
    }

    reset() {
        this.dataSource = new MatTableDataSource<ClaimsTableInterface>();
        this.selection.clear();
    }
}
