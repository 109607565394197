import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from 'src/app/services/http.service';
import { UtilService } from 'src/app/services/util.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';

@Component({
    selector: 'app-subscriber-profile-new',
    templateUrl: './subscriber-profile-new.component.html',
    styleUrls: ['./subscriber-profile-new.component.css']
})

export class SubscriberProfileNewComponent implements OnInit {
    public subscriberProfileForm: FormGroup;
    user_id: string;
    firstName: string;
    lastName: string;
    email: string;

    constructor(
        private httpService: HttpService,
        private formBuilder: FormBuilder,
        public auth: AuthService,
        private router: Router,
        private snackbarSvc: SnackbarService,
        private utilService: UtilService,
    ) { }

    ngOnInit() {
        //If Subscriber takes user_id from session
        if (this.auth.isSubscriber()) {
            this.user_id = JSON.parse(sessionStorage.getItem('userdata')).user_id;
        } else if (this.auth.isCorporate()) {
            this.user_id = JSON.parse(sessionStorage.getItem('userdata')).user_id;
        }

        this.firstName = JSON.parse(sessionStorage.getItem('userdata')).firstname;
        this.lastName = JSON.parse(sessionStorage.getItem('userdata')).lastname;
        this.email = JSON.parse(sessionStorage.getItem('userdata')).email;

        const subscriberDetails = JSON.parse(sessionStorage.getItem('userdata'));

        this.subscriberProfileForm = this.formBuilder.group({
            user_id: new FormControl({ value: '', disabled: true }, Validators.required),
            firstname: new FormControl({ value: '', disabled: false }, Validators.required),
            lastname: new FormControl({ value: '', disabled: false }, Validators.required),
            email: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.email]),
            dob: new FormControl({ value: '', disabled: false }, Validators.required),
            nationality: new FormControl({ value: '', disabled: false }, Validators.required),
            gender: new FormControl({ value: '', disabled: false }, Validators.required),
            address: new FormControl({ value: '', disabled: false }, Validators.required),
            address2: new FormControl({ value: '', disabled: false }),
            postcode: new FormControl({ value: '', disabled: false }, Validators.required),
            state: new FormControl({ value: '', disabled: false }, Validators.required),
            country: new FormControl({ value: '', disabled: false }, Validators.required),
            mobile: new FormControl({ value: '', disabled: false }, Validators.required),
            phone_no: new FormControl({ value: '', disabled: false }, Validators.required),
            height: new FormControl({ value: '', disabled: false }, Validators.required),
            race: new FormControl({ value: '', disabled: false }, Validators.required),
            hbp: new FormControl({ value: '', disabled: false }, Validators.required),
            diabetes: new FormControl({ value: '', disabled: false }, Validators.required),
            smoker: new FormControl({ value: '', disabled: false }, Validators.required),
        });

        this.profileFormReport();
    }

    get f() {
        return this.subscriberProfileForm.controls;
    }

    profileFormReport() {
        this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyAccount', {}).subscribe((res) => {
            let genderValue = '';
            if (res.data.gender == 'Male') {
                genderValue = "1";
            } else if (res.data.gender == 'Female') {
                genderValue = "2";
            } else {
                genderValue = "";
            }

            this.subscriberProfileForm.patchValue({
                user_id: res.data.id,
                firstname: res.data.firstname,
                lastname: res.data.lastname,
                email: res.data.email,
                gender: genderValue,
                dob: res.data.dob,
                nationality: res.data.nationality,
                address: res.data.address,
                address2: res.data.address2,
                postcode: res.data.postcode,
                state: res.data.state,
                country: res.data.country,
                mobile: res.data.mobile,
                phone_no: res.data.phone_no,
                height: res.data.height,
                hbp: res.data.treatement_for_high_blood_pressure,
                diabetes: res.data.diabetes,
                smoker: res.data.smoker,
                doctor_id: res.data.doctor_id
            });
        });
    }

    onSubmit() {
        let profileValues = this.subscriberProfileForm.value;
        profileValues.dob = this.utilService.convertDateStringtoFormat(profileValues.dob, 'YYYY-MM-DD');

        this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'updateMyAccount', profileValues).subscribe(data => {
            this.snackbarSvc.openSnackBar("Profile Data Updated Sucessfully");
            this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
        }, (err) => {
            console.log(err);
            this.snackbarSvc.openSnackBar(["Profile Data Updated Failed !" + err.error.message.routine]);
        });
    }
}


