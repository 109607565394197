import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appointmentColor' })

export class AppointmentColorPipe implements PipeTransform {
    transform(value: any, args?: any): string {
        switch (value) {
            case "1":
                return "#138496";
            case "3":
                return "#218838";
            case "4":
                return "#5A6268";
            case "5":
                return "#C82333";
            case "6":
                return "#83559d";
            default:
                console.log("ERROR Status code received: ", value);
                break;
        }
    }
}
