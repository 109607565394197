import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { LatestDeviceData } from '../../interface/doctor/doctor-latest-device-data.interface';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-subscriber-health-data',
    templateUrl: './subscriber-health-data.component.html',
    styleUrls: ['./subscriber-health-data.component.css']
})

export class SubscriberHealthDataComponent implements OnInit {
    latest_data: LatestDeviceData;
    programBtn = '<i class="fas fa-lg fa-angle-down mr-3"></i>Program';
    isPocketDoc = false;
    isHeartCheck = false;
    subscriberId: number;
    healthData: any;

    constructor(
        private subDataSvc: SubscriberDataNewService,
        private doctorDataSvc: DoctorDataService,
        private auth: AuthService,
    ) { }

    ngOnInit() {
        this.openHeartCheck();
    }

    openHeartCheck() {
        this.getLatestHealthData();
        this.programBtn = '<i class="fas fa-lg fa-hospital-user mr-3"></i>HeartCheck';
        this.isPocketDoc = false;
        this.isHeartCheck = true;
    }

    openPocketDoc() {
        this.getLatestHealthData();
        this.programBtn = '<i class="fas fa-lg fa-user-md mr-3"></i>PocketDoc';
        this.isPocketDoc = true;
        this.isHeartCheck = false;
    }

    async getLatestHealthData() {
        let dataService = null;

        if (this.auth.isDoctor()) {
            const res = await this.doctorDataSvc.getSub().pipe(first()).toPromise();
            this.subscriberId = Object(res).id;
            dataService = this.doctorDataSvc.getLatestDeviceData({ sub_id: this.subscriberId });
        } else if (this.auth.isSubscriber()) {
            dataService = this.subDataSvc.latestDeviceData();
        }

        if (dataService) {
            const res = await dataService.toPromise();
            if (res.data) this.healthData = res.data;
        }
    }
}
