import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from 'src/app/services/http.service';
import { map } from 'rxjs/operators'
import { MatDialog } from '@angular/material';
import { PopulateProfileDialogComponent } from 'src/app/components/common/populate-profile-dialog/populate-profile-dialog.component';

@Injectable({
    providedIn: 'root'
})

export class ProfileGuard implements CanActivateChild {
    constructor(
        private httpService: HttpService,
        public dialogService: MatDialog,
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyAccount', {}).pipe(map(res => {
            if (res.data.dob == null) {
                this.dialogService.open(PopulateProfileDialogComponent,
                    { width: '350px' });
                return false;
            } else { return true };
        }));
    }
}
