import { Component, ViewChild } from '@angular/core';
import { AuthService } from "./auth.service";
import { LayoutService } from 'src/app/services/common/layout/layout.service';
import { MatSidenav } from '@angular/material';
import { DataService } from './services/data.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent {
    @ViewChild('sidenav', { static: false }) public sidenav: MatSidenav;
    username: string;

    constructor(
        public auth: AuthService,
        private layoutService: LayoutService,
        private dataSvc: DataService,
    ) { }

    ngOnInit() { }

    ngAfterViewInit() {
        this.dataSvc.currentUsername.subscribe(res => this.username = res);
        this.layoutService.setSidenav(this.sidenav);
        this.dataSvc.updateUsername(this.auth.getLoginDetails() ? JSON.parse(this.auth.getLoginDetails()).firstname : "");
    }
}
