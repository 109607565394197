import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { ConfirmDialogComponent } from 'src/app/components/common/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/common/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from 'src/app/components/common/success-dialog/success-dialog.component';
import { WaitingDialogComponent } from 'src/app/components/common/waiting-dialog/waiting-dialog.component';

@Component({
    selector: 'app-teleconsultation-dialog',
    templateUrl: './teleconsultation-dialog.component.html',
    styleUrls: ['./teleconsultation-dialog.component.css']
})

export class SubsTeleconsultationDialogComponent implements OnInit {
    is_disabled: boolean = true;
    is_hidden: boolean = true;
    is_loading: boolean = true;

    teleconsult_available: boolean = true;
    teleconsultation_type: any[] = [];

    doc_available: boolean = true;
    doctors: any[] = [];

    createTeleconsult: FormGroup;
    confirmationDialog: MatDialogRef<ConfirmDialogComponent>;
    errorDialog: MatDialogRef<ErrorDialogComponent>;
    waitDialog: MatDialogRef<WaitingDialogComponent>;
    isPaused: boolean = false;
    pollIntervalId: any;
    waitIntervalId: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private dialogRef: MatDialogRef<SubsTeleconsultationDialogComponent>,
        private dialog: MatDialog,
        private router: Router,
        public auth: AuthService,
        private subsDataService: SubscriberDataNewService,
        public _fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.createTeleconsult = this._fb.group({
            selected_teleconsult: new FormControl('', Validators.required),
            selected_doctor: new FormControl('', Validators.required),
        });

        this.initAvailableTelconsultation();
        this.is_loading = false;
    }

    async initAvailableTelconsultation() {
        const res = await this.subsDataService.getAvailableTeleconsultationType().toPromise();
        this.teleconsultation_type = res.data;

        if (this.teleconsultation_type.length <= 0) {
            this.teleconsult_available = false;
            return;
        }

        setTimeout(() => {
            this.setMaxWidthClass('teleconsult-wrapper');
        }, 10);
    }

    selectTeleconsultation(teleconsultation) {
        this.doctors = [];
        this.createTeleconsult.patchValue({
            selected_doctor: ''
        });

        let teleconsultationWrapper = document.getElementsByClassName('teleconsult-wrapper');
        for (let i = 0; i < teleconsultationWrapper.length; i++) {
            teleconsultationWrapper[i].classList.remove('mat-card-btn-selected');
        }

        let teleconsultationElement = document.getElementsByClassName(`teleconsult-${teleconsultation.id}`)[0];
        teleconsultationElement.classList.add('mat-card-btn-selected');

        this.createTeleconsult.patchValue({
            selected_teleconsult: teleconsultation.id
        });
        this.initAvailableDoctor(teleconsultation.id);
    }

    async initAvailableDoctor(teleconsultation_id) {
        const res = await this.subsDataService.getAvailableDoctor({ "teleconsultation_type_id": teleconsultation_id }).toPromise();
        this.doctors = res.data;

        if (this.doctors.length <= 0) {
            this.doc_available = false;
            return;
        }

        this.is_disabled = false;
        this.is_hidden = false;
    }

    selectDoctor(doctor) {
        let doctorWrapper = document.getElementsByClassName('doctor-wrapper');
        for (let i = 0; i < doctorWrapper.length; i++) {
            doctorWrapper[i].classList.remove('mat-card-btn-selected');
        }

        let doctorElement = document.getElementsByClassName(`doctor-${doctor.id}`)[0];
        doctorElement.classList.add('mat-card-btn-selected');

        this.createTeleconsult.patchValue({
            selected_doctor: doctor.id
        });
    }

    proceedTeleconsult() {
        this.close();
        const doctor_id = this.createTeleconsult.value.selected_doctor;
        const teleconsultation_type_id = this.createTeleconsult.value.selected_teleconsult;

        const teleconsult = this.teleconsultation_type.find((item) => {
            return item.id === teleconsultation_type_id;
        });

        this.confirmationDialog = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: {
                title: 'Proceed to Teleconsultation',
                message: `Every successful teleconsultation will deduct ${teleconsult.credit_cost} credit from your account.`
            }
        });

        this.confirmationDialog.afterClosed().subscribe((res) => {
            if (res) {
                this.subsDataService.createTeleconsultation({
                    dr_id: doctor_id,
                    teleconsultation_type_id: teleconsultation_type_id
                }).subscribe((res) => {
                    if (!res.status) {
                        this.createTeleconsultationFailed(res.data);
                    } else {
                        this.createTeleconsultationSuccess();
                    }
                });
            }
        })
    }

    createTeleconsultationSuccess() {
        let waitingTime = 180;

        this.waitDialog = this.dialog.open(WaitingDialogComponent, {
            width: '400px',
            disableClose: true,
            data: {
                title: `Please Wait (${waitingTime} seconds)`,
                message: 'Please wait while the doctor accepts your teleconsultation request.',
                cancel_text: 'Cancel Teleconsultation'
            }
        });

        this.pollIntervalId = setInterval(() => {
            if (!this.isPaused) this.getTeleconsultationStatus();
        }, 3000);

        this.waitIntervalId = setInterval(() => {
            waitingTime--;
            this.waitDialog.componentInstance.title = `Please Wait (${waitingTime} seconds)`;

            if (waitingTime === 0) {
                this.quitWaiting();
                this.cancelTeleconsultation('Teleconsultation Request Expired', 'Your teleconsultation request has expired.', true);
            }
        }, 1000);

        this.waitDialog.afterClosed().subscribe((cancel) => {
            if (cancel) {
                this.quitWaiting();
                this.cancelTeleconsultation();
            }
        });
    }

    quitWaiting() {
        this.waitDialog.close();
        clearInterval(this.pollIntervalId);
        clearInterval(this.waitIntervalId);
    }

    cancelTeleconsultation(title = 'Teleconsultation Cancelled', message = 'Your teleconsultation request has been cancelled.', expired = false) {
        this.subsDataService.cancelTeleconsultation().subscribe((res) => {
            if (expired) {
                this.createTeleconsultationFailed(message, title);
            } else {
                this.dialog.open(SuccessDialogComponent, {
                    width: '400px',
                    data: {
                        title: title,
                        message: message
                    }
                });
            }
        });
    }

    getTeleconsultationStatus() {
        this.isPaused = true;
        this.subsDataService.getTeleconsultationStatus().subscribe((res) => {
            if (!res.status) {
                this.quitWaiting();
                this.createTeleconsultationFailed(
                    'The doctor has rejected your teleconsultation request.',
                    'Teleconsultation Rejected'
                );
            } else {
                let appointmentId = res.data.appointment_id;
                let sessionId = res.data.session_id;
                let duration = res.data.duration;
                let hasTeleexam = res.data.has_teleexam;

                if (appointmentId && sessionId) {
                    this.quitWaiting();
                    this.router.navigate(['/video-call'], {
                        queryParams: {
                            session_id: sessionId,
                            appointment_id: appointmentId,
                            duration: duration,
                            has_teleexam: hasTeleexam,
                        }
                    });
                }
            }
            this.isPaused = false;
        });
    }

    createTeleconsultationFailed(message: any, title = 'Create Teleconsultation Failed') {
        this.errorDialog = this.dialog.open(ErrorDialogComponent, {
            width: '400px',
            data: {
                title: title,
                message: message
            }
        });

        this.errorDialog.afterClosed().subscribe((res) => {
            this.dialog.open(SubsTeleconsultationDialogComponent, {
                width: '600px',
            });
        });
    }

    close() {
        this.dialogRef.close();
    }

    setMaxWidthClass(value: string) {
        let divMaxWidth = 0;
        const elements = Array.from(document.getElementsByClassName(value));

        elements.forEach(function (item: any) {
            if (item.offsetWidth > divMaxWidth) {
                divMaxWidth = item.offsetWidth;
            }
        });

        elements.forEach(function (item: any) {
            item.style.width = divMaxWidth + "px";
        });
    }
}
