import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { SubscriberAppointmentNewComponent } from '../subscriber-appointment-new.component';
import { ConfirmDialogComponent } from 'src/app/components/common/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-subscriber-add-appointment-dialog',
    templateUrl: './subscriber-add-appointment-dialog.component.html',
    styleUrls: ['./subscriber-add-appointment-dialog.component.css']
})

export class SubscriberAddAppointmentDialogComponent implements OnInit {
    selectDateAndTimeForm: FormGroup;
    index: number = 0;
    appointmentTypes: any[] = ['None', 'Video', 'In-Person'];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public _fb: FormBuilder,
        private subDataSvc: SubscriberDataNewService,
        public dialogRef: MatDialogRef<SubscriberAppointmentNewComponent>,
        private dialog: MatDialog,
        public datePipe: DatePipe,
        public _sb: SnackbarService,
    ) { }

    ngOnInit() {
        if (this.data) {
            if (this.data.appointment_type) this.index = this.data.appointment_type;
            if (this.data.render_appointment) this.render_appointment = this.data.render_appointment;
        }

        this.selectDateAndTimeForm = this._fb.group({
            date: new FormControl('', Validators.required),
            time: new FormControl('', Validators.required),
        });
    }

    selectDateAndTime() {
        this.close();

        let data_date = this.datePipe.transform(this.selectDateAndTimeForm.value.date, 'yyyy-MM-dd');
        let data_time = this.selectDateAndTimeForm.value.time;
        let data_type = this.appointmentTypes[this.index];

        let data = {
            appointment_type: data_type.toLowerCase(),
            datetime: [`${data_date} ${data_time}`]
        }

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: {
                title: 'Proceed to Add Appointment',
                message: 'Every successful appointment will deduct 1 credit from your account.'
            }
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                // TODO: Update addAppointment() to accept the new data format
                this.subDataSvc.addAppointment(data).subscribe((res) => {
                    if (res.status == true) {
                        this._sb.openSnackBar('Appointment Added Successfully');
                    } else {
                        this._sb.openSnackBar(res.data);
                    }
                }, (err) => {
                    this._sb.openSnackBar(`Error adding appointment ${err.message}`);
                });
            }

            this.render_appointment();
        })
    }

    render_appointment() { }

    close() {
        this.dialogRef.close();
    }
}
