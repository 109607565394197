import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';

@Injectable({
    providedIn: 'root'
})

export class DoctorInvitationGuard implements CanActivate {
    constructor(
        private subDataSvc: SubscriberDataNewService,
        private _sb: SnackbarService,
        private auth: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.auth.isSubscriber()) {
            return this.subDataSvc.getBillingInfo().pipe(map((res => {
                if (Object(res.data).dr_id == null) {
                    this._sb.openSnackBar("Please await doctor's invitation.")
                    return false;
                } else {
                    return true;
                }
            })));
        } else {
            return true;
        }
    }
}
