import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';

@Component({
    selector: 'app-subscriber-invoice-view',
    templateUrl: './subscriber-invoice-view.component.html',
    styleUrls: ['./subscriber-invoice-view.component.css'],
})

export class SubscriberInvoiceViewDialogComponent implements OnInit {
    invoice_id: any = null;
    invoice_detail: any = {
        id: null,
        invoice_no: null,
        description: null,
        unit_price: null,
        quantity: null,
        tax_percentage: null,
        sub_amount: null,
        tax_amount: null,
        total_amount: null,
        status: null,
        created_at: null,
        has_doctor: null,
        user: {
            id: null,
            firstname: null,
            lastname: null,
            address_one: null,
            address_two: null,
            postcode: null,
            state: null,
            country: null,
            phone: null,
        },
        doctor: {
            id: null,
            firstname: null,
            lastname: null,
            provider: null,
            provider_license_no: null,
            medical_reg_no: null,
            mma_no: null,
            address_one: null,
            address_two: null,
            postcode: null,
            state: null,
            country: null,
            phone: null,
        },
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private dialogRef: MatDialogRef<SubscriberInvoiceViewDialogComponent>,
        private subscriberDataSvc: SubscriberDataNewService,
    ) { }

    ngOnInit() {
        this.invoice_id = this.data ? this.data.invoice_id : null;
        this.getInvoiceDetail();
    }

    getInvoiceDetail() {
        this.subscriberDataSvc.getInvoiceDetail(this.invoice_id).subscribe((res) => {
            this.invoice_detail = res.data;
        });
    }
}