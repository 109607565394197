import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appointmentPipe' })

export class AppointmentPipePipe implements PipeTransform {
    transform(value: any): string {
        switch (value) {
            case "1":
                return "Pending";
            case "2":
                return "Deleted";
            case "3":
                return "Confirmed";
            case "4":
                return "Rejected";
            case "5":
                return "Cancelled";
            case "6":
                return "Completed";
            default:
                console.log("ERROR Status code received: ", value);
                break;
        }
    }
}
