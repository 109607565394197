import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { BooleanToBinaryPipe } from 'src/app/pipes/boolean-pipes/boolean-to-binary.pipe';
import { BinaryToBooleanPipe } from 'src/app/pipes/boolean-pipes/binary-to-boolean.pipe';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { AddItemDialogComponent } from '../../doctor/doctor-medical-summary/add-item-dialog/add-item-dialog.component';
import { Race } from '../../../../assets/lists/raceList';
import { DatePipe, Location } from '@angular/common';

@Component({
    selector: 'app-medical-summary-new',
    templateUrl: './medical-summary-new.component.html',
    styleUrls: ['./medical-summary-new.component.css']
})

export class MedicalSummaryNewComponent implements OnInit {
    raceList = Race;
    user_id: number;
    summaryDataForm: FormGroup;
    doctorSummaryDataForm: FormGroup;
    disabled: boolean = this.auth.isDoctor() ? false : true;
    subId: any;
    hbp: any;

    // SMH variables
    smhData: any;
    smhList: any[] = [];
    smh_item = {};
    smh_since = {};

    // PSH variables
    pshData: any;
    pshList: any[] = [];
    psh_item = {};
    psh_since = {};

    // CM variables
    cmData: any;
    cmList: any[] = [];
    cm_item = {};
    cm_since = {};

    // DA variables
    daData: any;
    daList: any[] = [];
    da_item = {};
    da_since = {};

    constructor(
        private dataService: SubscriberDataNewService,
        private doctorDataService: DoctorDataService,
        public _fb: FormBuilder,
        private route: ActivatedRoute,
        public snackBarSvc: SnackbarService,
        public auth: AuthService,
        public router: Router,
        private binaryToBoolPipe: BinaryToBooleanPipe,
        public dialog: MatDialog,
        public boolToBinaryPipe: BooleanToBinaryPipe,
        public _location: Location,
        public _dp: DatePipe,
    ) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.user_id = +params['id']; // Get the user_id from the URL
        });

        this.summaryDataForm = this._fb.group({
            created: new FormControl({ value: 'No Data', disabled: true }),
            user_id: new FormControl({ value: 'No Data', disabled: true }),
            fullname: new FormControl({ value: 'No Data', disabled: true }),
            mrn: new FormControl({ value: 'No Data', disabled: true }),
            dob: new FormControl({ value: 'No Data', disabled: true }),
            age: new FormControl({ value: 'No Data', disabled: true }),
            race: new FormControl({ value: 'No Data', disabled: this.disabled }),
            gender: new FormControl({ value: 'No Data', disabled: true }),
            hbp: new FormControl({ value: false, disabled: this.disabled }),
            diabetes: new FormControl({ value: false, disabled: this.disabled }),
            smoker: new FormControl({ value: false, disabled: this.disabled }),
            smh_data: this._fb.array([]),
        });

        this.getMedicalSummary();
        this.getSelectLists();
    }

    getMedicalSummary() {
        const medSumEndpoint = this.auth.isSubscriber() ? this.dataService.getMyMedicalSummary() : this.doctorDataService.getMedicalSummaryId(this.user_id);

        medSumEndpoint.subscribe((res) => {
            this.patchSummaryData(res.data);
        }, (err) => {
            this.snackBarSvc.openSnackBar(`Error: ${err}`);
        });
    }

    patchSummaryData(data) {
        if (data.user_id != this.user_id) return this.snackBarSvc.openSnackBar('Invalid User');

        this.summaryDataForm.patchValue({
            created: this._dp.transform(data.created, "dd/MM/yyyy"),
            user_id: data.user_id,
            fullname: [data.firstname, data.lastname].filter(Boolean).join(' '),
            mrn: data.mrn,
            dob: data.dob ? data.dob : 'No Data',
            age: data.age,
            gender: data.gender,
            hbp: this.convertBool(data.hbp),
            diabetes: this.convertBool(data.diabetes),
            smoker: this.convertBool(data.smoker),
            race: data.race ? data.race : 'No Data',
        });

        this.smhList = data.smh_data;
        this.pshList = data.psh_data;
        this.cmList = data.cm_data;
        this.daList = data.da_data;
    }

    getSelectLists() {
        this.doctorDataService.getSignificantMedicalHistoryList().subscribe((res) => {
            this.smhData = res.data;
        });
        this.doctorDataService.getPastSurgicalHistoryList().subscribe((res) => {
            this.pshData = res.data;
        });
        this.doctorDataService.getMedicationList().subscribe((res) => {
            this.cmData = res.data;
        });
        this.doctorDataService.getDrugAllergyList().subscribe((res) => {
            this.daData = res.data;
        });
    }

    addItem(name: string) {
        let list_title = "";

        switch (name) {
            case 'smh':
                list_title = 'Significant Medical History';
                break;
            case 'psh':
                list_title = 'Past Surgical History';
                break;
            case 'cm':
                list_title = 'Current Medication';
                break;
            case 'da':
                list_title = 'Drug Allergies';
                break;
            default:
                break;
        }

        this.openDialog(this[`${name}Data`], `${name}List`, list_title);
    }

    removeItem(name: string, value: string) {
        this[`${name}List`] = this[`${name}List`].filter((item: { name: string; }) => item.name !== value);
    }

    fullSubmit() {
        this.patchObjects(this.smhData, this.smhList, 'smh_item', 'smh_since', 'desc');
        this.patchObjects(this.pshData, this.pshList, 'psh_item', 'psh_since', 'desc');
        this.patchObjects(this.cmData, this.cmList, 'cm_item', 'cm_since', 'name');
        this.patchObjects(this.daData, this.daList, 'da_item', 'da_since', 'name');

        let data = {
            sub_id: this.user_id,
            race: this.summaryDataForm.value.race,
            hbp: this.summaryDataForm.value.hbp ? "1" : "0",
            diabetes: this.summaryDataForm.value.diabetes ? "1" : "0",
            smoker: this.summaryDataForm.value.smoker ? "1" : "0",
            smh_item: this.smh_item,
            smh_since: this.smh_since,
            psh_item: this.psh_item,
            psh_since: this.psh_since,
            cm_item: this.cm_item,
            cm_since: this.cm_since,
            da_item: this.da_item,
            da_since: this.da_since,
        }

        this.doctorDataService.updateMedicalSummary(data).subscribe((res) => {
        }, (err) => {
            this.snackBarSvc.openSnackBar(`There is an error updating this record. ${err}`)
        }, () => {
            this.snackBarSvc.openSnackBar(`${this.user_id}'s Medical Summary has been updated.`);
        });
    }

    patchObjects(api_data_list, local_data_list, obj_item, obj_since, api_var) {
        for (let i = 0; i < local_data_list.length; i++) {
            for (let j = 0; j < api_data_list.length; j++) {
                if (api_data_list[j][api_var] == local_data_list[i].name) {
                    this[obj_item][api_data_list[j].id] = local_data_list[i].name;
                    this[obj_since][api_data_list[j].id] = local_data_list[i].since;
                }
            }
        }
    }

    openDialog(list_data, list_name, title) {
        const dialogRef = this.dialog.open(AddItemDialogComponent, {
            width: '350px',
            height: '300px',
            data: { list: list_data, name: title },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this[list_name].push({ name: res.name, since: res.since });
                this.snackBarSvc.openSnackBar(`${title} added Successfully`);
            }
        })
    }

    convertBool(val: string | number) {
        if (this.auth.isSubscriber()) {
            return +val ? 'Yes' : 'No';
        } else if (this.auth.isDoctor()) {
            return +val ? true : false;
        }
    }
}
