import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css']
})

export class ConfirmDialogComponent implements OnInit {
    title: string = 'Please Wait';
    message: string = 'Please wait while we process your request.';
    cancel_text: string = 'Cancel';
    confirm_text: string = 'Confirm';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        private router: Router,
        public auth: AuthService,
    ) { }

    ngOnInit() {
        if (this.data) {
            if (this.data.title) this.title = this.data.title;
            if (this.data.message) this.message = this.data.message;
            if (this.data.cancel_text) this.cancel_text = this.data.cancel_text;
            if (this.data.confirm_text) this.confirm_text = this.data.confirm_text;
        }
    }

    confirm(status: boolean) {
        this.dialogRef.close(status);
    }
}
