import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SubscriberInvoiceInterface } from '../../interface/subscriber/subscriber-invoice.interface';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SubscriberInvoiceViewDialogComponent } from './subscriber-invoice-view/subscriber-invoice-view.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'app-subscriber-invoice',
    templateUrl: './subscriber-invoice.component.html',
    styleUrls: ['./subscriber-invoice.component.css'],
})

export class SubscriberInvoiceComponent implements OnInit {
    dataList: any;
    dataSource = new MatTableDataSource<SubscriberInvoiceInterface>();

    displayedColumns: string[] = ['id', 'description', 'tax', 'amount', 'status', 'created', 'action'];
    pageSizeOptions: number[] = [5, 10, 20];
    pageSize = 100;

    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    constructor(
        private subscriberDataSvc: SubscriberDataNewService,
        private breakpointObserver: BreakpointObserver,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.getInvoices();
    }

    getInvoices() {
        this.subscriberDataSvc.getInvoiceList().subscribe((res) => {
            this.dataList = res.data;
            this.dataSource = new MatTableDataSource<SubscriberInvoiceInterface>(this.dataList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
    }

    openInvoice(row: any) {
        let width = '95%';
        if (this.breakpointObserver.isMatched(Breakpoints.Large)) width = '60%';

        const dialogRef = this.dialog.open(SubscriberInvoiceViewDialogComponent, {
            width: width,
            data: { invoice_id: row.id },
        });
    }
}