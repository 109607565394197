import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import appConstants from '../config/app.constants';
import { DataService } from '../services/data.service';
import { HttpService } from '../services/http.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css']
})

export class LayoutComponent implements OnInit {
    isOpen: boolean;
    showSpinner: true;
    username: string;
    isExpanded = false;
    userProfileData = [];
    user_id: number;
    user_doctor_id: number;

    @ViewChild('sidenav', { static: false }) public sidenav: MatSidenav;
    constructor(
        public auth: AuthService,
        private router: Router,
        private dataService: DataService,
        private httpService: HttpService,
    ) { }

    ngOnInit() {
        if (this.auth.isLoggednIn()) {
            this.dataService.currentUsername.subscribe(res => this.username = res);
        }
        if (this.auth.isSubscriber()) {
            this.user_id = JSON.parse(sessionStorage.getItem('userdata')).user_id;
            this.user_doctor_id = JSON.parse(sessionStorage.getItem('userdata')).doctor_id;
        }
    }

    sidebarDashboard() {
        if (JSON.parse(sessionStorage.getItem("userdata")).category_name == "WeCheckup") {
            this.router.navigate(['/health-data']);
        } else {
            this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
        }
    }

    sideNavToggle() {
        this.sidenav.toggle();
    }

    profileFormReport() {
        this.httpService.commonPost(appConstants.apiBaseUrl + 'get_subscriber_details', { user_id: this.user_id })
            .subscribe((data) => {
                this.userProfileData = data.data[0];
                this.dataService.setProfileData(this.userProfileData);
            })
    }

    profileClick() {
        if (this.auth.isSubscriber()) {
            this.router.navigate(["/sub-profile"]);
        } else if (this.auth.isDoctor()) {
            this.router.navigate(["doc-profile"]);
        }
    }
}
